.load {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    position: fixed;
    background: transparent;
    z-index: 1000;
  
    & + div {
     // height: 100vh;
      overflow: hidden;
    }
  
    &.loaded {
      animation: ease loaded 0.5s;
    }
  }
  
  .load__icon {
    animation: linear load 2s infinite;
    margin: auto;
    width: 32px;
    height: 32px;
  }

  .load__icon1 {
    animation: linear load 2s infinite;
    margin: auto;
    width: 10px;
    height: 15px;  
    position: relative; 
    right: 10px;
  }  

  .load__icon-wrap {
    margin: auto;
  }
  
  @keyframes load {
    from {
      transform: rotate(0deg) scale(2);
    }
    to {
      transform: rotate(360deg) scale(2);
    }
  }
  
  @keyframes loaded {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  #svg-spinner{
    -webkit-transition-property: -webkit-transform;
    -webkit-transition-duration: 1.2s;
    -webkit-animation-name: rotate;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    
    -moz-transition-property: -moz-transform;
    -moz-animation-name: rotate; 
    -moz-animation-duration: 1.2s; 
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    
    transition-property: transform;
    animation-name: rotate; 
    animation-duration: 1.2s; 
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  
  @-webkit-keyframes rotate {
      from {-webkit-transform: rotate(0deg);}
      to {-webkit-transform: rotate(360deg);}
  }
  
  @-moz-keyframes rotate {
      from {-moz-transform: rotate(0deg);}
      to {-moz-transform: rotate(360deg);}
  }
  
  @keyframes rotate {
      from {transform: rotate(0deg);}
      to {transform: rotate(360deg);}
  }

  // ***************************** LOAD **********************************