$color_4: #fff;
$color_5: #FF5370;
$color_6: #292929;
$background-color_1: white;
$background-color_2: #007ACC;
$border-color_1: #FF5370;

@font-face {
    font-family: "tahoma", sans-serif;
    src: url("../assets/fonts/Tahoma.woff") format("woff"),
    url('../assets/fonts/tahoma.ttf')  format('truetype'), /* Safari, Android, iOS */

    }
body
{
    font-family: "tahoma", sans-serif !important;
    overflow: hidden;
}

.login-pg {
    
    font-size: 14px;
    color: #9E9E9E;
    background-color: #ffffff;
    background-image: url("../assets/img/bg-lft.png");
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
}

a {
    text-decoration: none !important;
    color: #007ACC;
    text-decoration: none;
}

.forgot-psd {
    a {
        color: #9E9E9E;

        &:hover {
            color: #007ACC;
        }
    }
}

.login-section {
    padding: 20px;
}
.log-txt{
    font-weight: 400;
    font-size: 39px;
    line-height: 57px;
    letter-spacing: 0.01em;
    color: #2F5496;
    padding-left: 54px;
    @media(max-width:1024px)
    {
        padding-left: 0px;
    }
}
.main-img-div {
  
    position: relative;
    top: 20%;
    @media(max-width:1024px)
    {
        top: 25%;             
    }
    @media(max-width:900px)
    {
        top: 5%;             
    }
}

.main-img {
    width: 550px;
}

.inputContainer {
    position: relative;
    height: 3.25rem;
    width: 100%;
    margin-bottom: 1.5rem;
    #psdwrd{
        font-weight: 500;
        letter-spacing: 2px;
        font-size: 20px!important;
    }
}

.input {
    position: absolute;
    top: 0rem;
    left: 0rem;
    height: 100%;
    width: 100%;
    border: 1px solid #9E9E9E !important;
    border-radius: 0.325rem !important;
    font-size: 1rem !important;
    padding: 0.625rem 1.25rem !important;
    background: none;
    z-index: 1;
    outline: none !important;
    box-shadow: none !important;

    &:focus {
        + {
            .label {
                top: -0.625rem;
                left: 0.938rem;
                z-index: 10;
                font-size: 0.9rem;
                // font-weight: 500;
                color: #007ACC;
                outline: none !important;
                box-shadow: none !important;
            }
        }

        border: 2px solid #007ACC;
        outline:1px solid #007ACC !important;
        box-shadow: none !important;
    }

    &:not(:placeholder-shown)+ {
        .label {
            top: -0.625rem;
            left: 0.938rem;
            z-index: 10;
            font-size:13px;
        }
    }
}

input {
    &:focus {
        border: 2px solid #007ACC !important;
        outline: none !important;
        box-shadow: none !important;
        font-size: 0.9rem!important;
    }
}

.form-control {
    &:focus {
        border: 2px solid #007ACC !important;
        outline: none !important;
        box-shadow: none !important;
    }
}

.label {
    position: absolute;
    top: 0.838rem;
    left: 0.938rem;
    padding: 0 4px;
    background-color: $background-color_1;
    color: #9E9E9E;
    font-size: 0.9rem;
    transition: 0.2s;
    // z-index: 2;
    font-weight: 400;
}

.mr-btm {
    margin-bottom: 6px;
}

span.forgot-psd {
    float: right;
    position: relative;
}

.logo-img {
    padding: 0.5rem 0rem;
}

.logo {
    p {
        color: #9E9E9E;
        font-size: 1.02rem;
    }

    .log-fnt {
        color: #2F5496;
        font-weight: 600;
        font-size: 2.8rem;

        .log-360 {
            color: #007ACC;
        }
    }

    .tm {
        color: #007ACC;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
    }
}

.btnbox {
    .fullwidthbtn {
        align-items: center;
        background-color: $background-color_2;
        border: none;
        border-radius: 5px;
        color: $color_4;
        cursor: pointer;
        display: flex;
        height: 45px;
        justify-content: center;
        text-align: center;
        text-decoration: none;
        width: 100%;
       font-size: 16px;
       &:hover{
        background-color: #054C7B;
       }
    }
}

.fr-psd-div {
    label {
        color: #9E9E9E;
        font-weight: 400;
    }
}

.sp-line {
    line-height: 3rem;

    input {
        vertical-align: middle;
    }
}

.sp-line-2 {
    line-height: 1.5rem;
    /* text-align: inherit; */
    padding: 0px 20px;
    font-size: 14px;
}

.login-content {
    position: relative;
    top: 20%;
    @media(max-width:1024px)
    {
        top: 25%;             
    }
    @media(max-width:900px)
    {
        top: 5%;             
    }
}

.ftr-top {
    top: 150px;
    position: relative;
    span{
        position: fixed;
    left: 0;
    bottom: 15px;
    width: 100%;
    /* background-color: red; */
    /* color: white; */
    text-align: center;
    @media(max-width:1024px)
    {
        position: relative;
        bottom: -100px;
        padding-bottom: 30px;
    }
    }
    @media(max-width:1024px)
    {
        top: 0;             
    }
   
}

.error {
    .input {
        border-color: $border-color_1 !important;
        outline: none !important;

        &:focus {
            + {
                .label {
                    color: $color_5;
                }
            }
        }
    }

    label {
        color: $color_5;
    }
}

.fr-psd {
    h2 {
        color: $color_6;
        line-height: 36px;
    }

    p {
        color: $color_6;
        line-height: 36px;
        font-size: 16px;
    }
}

.forget-ps-con {
    top: 35%;
}

.psd-request {
    color: #292929;
    padding-bottom: 20px;
    h2{
  font-size: 1.9rem;
    }

    p {
        padding-top: 20px;
        color: $color_6;
    }

    a {
        color: $color_6;
    }

}

.reset-psd {
    color: $color_6;
    padding-bottom: 15px;

    h2 {
        font-size: 2rem;
    }
}

.password-req {
    top: 45%;
}



@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:landscape) {
    .ftr-top {
        top: 80px;
    }
}

@media all and (device-width: 820px) and (device-height: 1180px) and (orientation:portrait) {
    .ftr-top {
        top: 120px;
    }
}

@media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
    .ftr-top {
        top: 80px;
    }
}

@media (min-width: 1400px) {
    .container {
        max-width: 1320px !important;
    }

    .container-lg {
        max-width: 1320px !important;
    }

    .container-md {
        max-width: 1320px !important;
    }

    .container-sm {
        max-width: 1320px !important;
    }

    .container-xl {
        max-width: 1320px !important;
    }

    .container-xxl {
        max-width: 1320px !important;
    }
}

.guestlogin
{
    width: 100%;
    //  position: absolute;
    // overflow-y: auto;
    height: 100vh;
    display: flex;
    background: #F7F8F9;
    .gl-left
    {
        padding: 40px 70px;
        height: 100%;
        h1 {
            color: #007acc;
            margin-bottom: 0px;
        }
        h4 {
            font-weight: 400;
            font-size: 22px;
            line-height: 32px;
            color: #2d2d2d;
        }
        .min-data
        {
            display: flex;
            height: 500px;
            .log-txt
            {
                
                font-style: normal;
                font-weight: 400;
                font-size: 46px;
                line-height: 72px;
                letter-spacing: 0.01em;
                align-self: center;

                color: #2F5496;
            }
        }
    }
    .doodle-dl
    {
        position: absolute;
        bottom: 0;
    }
    .doodle-dr
    {
        position: absolute;
        right: 0px;
        top: 0;
        z-index: 999;
    }
    .gl-right
    {
        background-color: #fff;
        padding: 40px 70px;
        height: 100%;
        .frm-sec
        {
            height: 450px;
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            justify-content: center;
            
           
            .login-content
            {
                width: 500px;
                h2{
                    
                    font-style: normal;
                    font-weight: 600;
                    font-size: 44px;
                    line-height: 66px;
                    text-align: left;
                    color: #2F5496;                    
                    padding-bottom: 25px;

                    // align-self: center;
                }
                .lt-sp
                {
                    letter-spacing: 16px;
                    font-size: 1.2rem !important;
                    font-weight: 400;

                }
                .email-edit
                {
                    padding: 20px;
                    background: #F7F8F9;
                    border-radius: 15px 15px 0px 0px;
                    margin-bottom: 30px;
                    span{
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 23px;
                        color: #A3A3A3;
                    }
                    .em-dt{
                        
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 35px;
                        color: #11102D;
                        a{
                            svg{
                                float: right;
                                color: #11102D;
                            }
                        }

                    }
                }
            }
        }
        
    }
}

.row-flex {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    margin: 0px;
  }
  
.password-container
{
    span
    {
        svg{
        position: relative;
        float: right;
        font-size: 16px;
        margin-right: 20px;
        margin-top: 18px;
        vertical-align: middle;
        z-index: 999;
        cursor: pointer;

    }
}
}
input[type="password"] 
{
    font:small-caption;
    font-size:16px
}