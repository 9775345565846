@media (max-width:600px)
{
    .choose-data {
        position: unset;
    }
    .pull-left {
        position: unset;
    }
    .pd-right-45
    {
        padding-right: 0px !important;
    }
    .inputformContainer 
    {
        label{
            font-size: 12px !important;
        }
    }
}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px)
{
    .choose-data {
        position: unset;
    }
    .pd-right-45
    {
        padding-right: 0px!important;
    }
   
   
}

@media all and (device-width: 820px) and (device-height: 1180px) and (orientation:portrait) {

.image {
    display: block;
    width: 120px;
    border-radius: 50%;
}
.upload-profile
    {
        width: 120px!important;
        height:120px!important;
    }
.upload-profile 
{
    svg
    {
        display: block;
        width: 120px;
        border-radius: 50%;
        margin: 0 auto;
        position: absolute;
        top: -27px;
    }
}
.inputformContainer label {
    font-size: 9px!important;
}
::placeholder { 
    font-size: 9px!important;
  }
  .crt-select {
    font-size: 9px!important;
}
.sort-inp {
     width: 8px;
    height: 8px;
    font-size: 9px !important;
    top: 0px;
}
.cat-label {
    font-size: 9px !important;
    vertical-align: baseline;
}
.sort-cate {
    font-size: 9px !important;
    vertical-align: baseline;
    padding-right: 0px;
}

  }
  @media all and (device-width: 1180px) and (device-height: 820px) and (orientation:landscape) 
  {
    .complete-btn, .arrow-iconcom {
        
        width: 9rem;
        margin-left: 13rem;
    }
    .pre-head
    {
       padding-left: 10px;
    }
    .repo-text
    {
        padding-left:10px;
    }
   
    
  }
  @media all and (device-width: 834px) and (device-height: 1112px) and (orientation:landscape) {
    .image {
        display: block;
        width: 100px!important;
        border-radius: 50%;
    }
    .upload-profile
    {
        width: 100px!important;
        height:100px!important;
    }
    .upload-profile svg
    {
        display: block;
        width: 100px;
        border-radius: 50%;
        margin: 0 auto;
        position: absolute;
        top: -27px;
    }
    .pad-bot-0
    {
        padding-top: 0px;
    }
    .inputformContainer label {
        font-size: 9px!important;
    }
    ::placeholder { 
        font-size: 9px!important;
      }
      .crt-select {
        font-size: 9px!important;
    }
    .sort-inp {
         width: 8px;
        height: 8px;
        font-size: 9px !important;
        top: 0px;
    }
    .cat-label {
        font-size: 9px !important;
        vertical-align: baseline;
    }
    .sort-cate {
        font-size: 9px !important;
        vertical-align: baseline;
        padding-right: 0px;
    }
    .pd-right-45
    {
        padding-right: 0px;
    }
    .pad-2 {
        margin-right: 1.5rem!important;
    }
      }
  @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
    .image {
        display: block;
        width: 120px;
        border-radius: 50%;
    }
    .upload-profile
    {
        width: 120px!important;
        height:120px!important;
    }
    .upload-profile svg
    {
        display: block;
        width: 120px;
        border-radius: 50%;
        margin: 0 auto;
        position: absolute;
        top: -27px;
    }
    .tb-main {
        color: #292929;
        font-family: 'tahoma', sans-serif;
        font-size: 8px;
    }
    .tb-nm {
        color: #9E9E9E;
        font-size: 9px;
  }
  .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  font-size: 10px;
  }
  .nav-pills .nav-link {
    font-size: 10px;
  }
  body {
    font-size: 11px;

  }
  .inputformContainer label {
    font-size: 9px!important;
}
::placeholder { 
    font-size: 9px!important;
  }
  .crt-select {
    font-size: 9px!important;
}
.sort-inp {
     width: 8px;
    height: 8px;
    font-size: 9px !important;
    top: 0px;
}
.cat-label {
    font-size: 9px !important;
    vertical-align: baseline;
}
.sort-cate {
    font-size: 9px !important;
    vertical-align: baseline;
    padding-right: 0px;
}
.pd-right-45
{
    padding-right: 0px!important;
}
  
}  

@media all and (device-width: 1024px) and (device-height: 768px)
{
    .image {
        display: block;
        border-radius: 50%;
    }
    .upload-profile
    {
        width: 130px!important;
        height:130px!important;
        background: #F7F8F9;
        box-shadow: 0px 36.622px 14.0854px rgba(0, 0, 0, 0.01), 0px 20.6585px 12.2073px rgba(0, 0, 0, 0.03), 0px 9.39024px 9.39024px rgba(0, 0, 0, 0.04), 0px 1.87805px 4.69512px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05);
        border-radius: 50%;
    }
    .upload-profile svg
        {
            display: block;
            width: 130px!important;
            border-radius: 50%;
            margin: 0 auto;
            position: absolute;
            top:-10px;
        }
}
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .upload-profile
    {
        width: 130px!important;
        height:130px!important;
        background: #F7F8F9;
        box-shadow: 0px 36.622px 14.0854px rgba(0, 0, 0, 0.01), 0px 20.6585px 12.2073px rgba(0, 0, 0, 0.03), 0px 9.39024px 9.39024px rgba(0, 0, 0, 0.04), 0px 1.87805px 4.69512px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05);
        border-radius: 50%;
    }

}

/* Portrait */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .upload-profile
    {
        width: 130px!important;
        height:130px!important;
        background: #F7F8F9;
        box-shadow: 0px 36.622px 14.0854px rgba(0, 0, 0, 0.01), 0px 20.6585px 12.2073px rgba(0, 0, 0, 0.03), 0px 9.39024px 9.39024px rgba(0, 0, 0, 0.04), 0px 1.87805px 4.69512px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05);
        border-radius: 50%;
    }

}

/* Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .upload-profile
    {
        width: 130px!important;
        height:130px!important;
        background: #F7F8F9;
        box-shadow: 0px 36.622px 14.0854px rgba(0, 0, 0, 0.01), 0px 20.6585px 12.2073px rgba(0, 0, 0, 0.03), 0px 9.39024px 9.39024px rgba(0, 0, 0, 0.04), 0px 1.87805px 4.69512px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05);
        border-radius: 50%;
    }

}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .upload-profile
    {
        width: 130px!important;
        height:130px!important;
        background: #F7F8F9;
        box-shadow: 0px 36.622px 14.0854px rgba(0, 0, 0, 0.01), 0px 20.6585px 12.2073px rgba(0, 0, 0, 0.03), 0px 9.39024px 9.39024px rgba(0, 0, 0, 0.04), 0px 1.87805px 4.69512px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05);
        border-radius: 50%;
    }

}

/* Portrait */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .upload-profile
    {
        width: 130px!important;
        height:130px!important;
    }
    .upload-profile svg
        {
            width: 130px!important;
            top: -20px !important;;
        }

}

/* Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .upload-profile
    {
        width: 130px!important;
        height:130px!important;
    }
    .upload-profile svg
        {
            width: 130px!important;
            top: -20px !important;;
        }


}

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 834px) 
  and (max-device-width: 1112px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .upload-profile
    {
        width: 130px!important;
        height:130px!important;
    }
    .upload-profile svg
        {
            width: 130px!important;
            top: -20px !important;;
        }


}

/* Portrait */
@media only screen 
  and (min-device-width: 834px) 
  and (max-device-width: 834px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .upload-profile
    {
        width: 130px!important;
        height:130px!important;
    }
    .upload-profile svg
        {
            width: 130px!important;
            top: -20px !important;;
        }


}

/* Landscape */
@media only screen 
  and (min-device-width: 1112px) 
  and (max-device-width: 1112px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .upload-profile
    {
        width: 130px!important;
        height:130px!important;
    }
    .upload-profile svg
        {
            width: 130px!important;
            top: -20px !important;;
        }

}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .upload-profile
    {
        width: 130px!important;
        height:130px!important;
    }
    .upload-profile 
    {
        svg
        {
            display: block;
            width: 130px!important;
            border-radius: 50%;
            margin: 0 auto;
            position: absolute;
            top: -20px !important;;
        }
    }
        .complete-btn, .arrow-iconcom {
        
            width: 9rem!important;
            margin-left: 13rem!important;
        }
        .pre-head
        {
           padding-left: 10px!important;
        }
        .repo-text
        {
            padding-left:10px!important;
        }
        /* .allergy-sort {
            margin-left: 30rem!important;
        } */
        .pad-3 {
            margin-right: 2rem!important;
        }
        .pad-2 {
            margin-right: 1.5rem!important;
        }


}

/* Portrait */
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .upload-profile
    {
        width: 130px!important;
        height:130px!important;
    }
    .upload-profile 
    {
        svg
        {
            width: 130px!important;
            top: -20px !important;;
        }
    }


}

/* Landscape */
@media only screen 
  and (min-device-width: 1366px) 
  and (max-device-width: 1366px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .upload-profile
    {
        width: 130px!important;
        height:130px!important;
    }
    .upload-profile 
    {
        svg
        {
            width: 130px!important;
            top: -20px !important;;
        }
    }


}

@media (max-width:1200px)
{
    .mobile-menu-icon {
        display: block; 
   }
   
   .guestlogin
    {
        .doodle-dl,.doodle-dr
        
        {
            display: none;
        }
        .gl-left
        {
            padding: 10px 15px!important;
            height: auto!important;
            h1 {
              font-size: 20px!important;
            }
            .min-data {
                height: 300px!important;
                .log-txt {          
                    font-size: 36px!important;
                    line-height: 52px!important;
                    padding-left: 0px !important;
                }
            }
        }
        .gl-right {
            padding: 10px 20px!important;
            height: auto !important;
            .frm-sec {
                 height: auto!important;
            }
            
    }
}
}

@media (max-width:600px)
{
.guestlogin
{
    .doodle-dl,.doodle-dr
    {
        display: none;
    }
   .gl-left {
        padding: 10px 20px!important;
        height: auto !important;
       .min-data {
            height: unset!important;
            .log-txt {          
                font-size: 36px!important;
                line-height: 52px!important;
                padding-left: 0px !important;
            }
        }
    }
    .gl-right {
        padding: 10px 20px!important;
        height: auto !important;
        .frm-sec {
            height: auto!important;
        }
        
    }
    
}

}

@media(max-width:1024px)
{
    .prj-overview-right
    {
        .card-view
        {
            padding: 5px 5px!important;
            .card{
                .card-body {
                    .grn-btn{
                        width: 60%!important;
                    }
                    
                }
            }
    
        }
    }
    .ct-btn {
        
        width: 108px!important; 
        font-size: 11px!important;
       
    }
}

