.inputformContainer {
	position: relative;
	height: 45px;
	width: 100%;
	margin-bottom: 1.2rem;
	color: #9E9E9E;
	label{
		position: absolute;
		top: 0.7rem;
		left: 0.538rem;
		padding: 0 4px;
		background-color: white;
		color: #9E9E9E;
		font-size: 0.6rem;
		transition: 0.3s;
		z-index: 0;
		font-weight: 400;
        font-size: 14px;
        // line-height: 23px;
	}
}
.inputformdata {
	position: absolute;
	top: 0rem;
	left: 0rem;
	height: 100%;
	width: 100%;
	border: 1px solid #9E9E9E;
	border-radius: 0.325rem;
	font-size: 0.6rem;
	padding: 0.225rem 0.6rem;
	background: none;
	z-index: 1;
	outline: none !important;
	box-shadow: none;
	&:focus {
		border: 1px solid #007ACC;
		outline: none !important;
		box-shadow: none;
		& + .label {
			top: -0.5rem;
			left: 0.538rem;
			z-index: 2;
			font-size: 12px;
			font-weight: 400;
			color: #007ACC;
			outline: none !important;
			box-shadow: none;
		}
	}
	&:not(:placeholder-shown) {
		& + .label {
			top: -0.5rem;
			left: 0.538rem;
			z-index: 2;
			font-size: 12px;
		}
	}
}
.form-select {
	height: 100%;
	width: 100%;
	border: 1px solid #9E9E9E;
	border-radius: 0.325rem;
	font-size: 0.6rem;
	padding: 0.225rem 0.6rem;
	-webkit-appearance: none;
}
::placeholder {
	color: #9e9e9ebb;
	font-size: 12px;
	font-weight: 500;
	color: #9E9E9E;
	opacity: 1;
	font-size: 12px;
}
.date-icon,.date-icon-allergy,.date-icon-fc, .date-icon-diagnosis, .date-icon-ondate, .date-icon-reviewdate,.date-icon-secdate, .date-icon-dategiven,.date-icon-dateplaced,.date-icon-datetime {
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	color: #aaa;
	z-index: 999;
}
.chk-bx {
	.sp-line {
		font-size: 0.6rem;
		color: #292929;
	}
}
.chk {
	vertical-align: middle;
	margin-right: 3px;
}
select {
	&:focus {
		outline: 0 !important;
		box-shadow: unset!important;
	}
	&:required {
		&:invalid {
			color: #9E9E9E;
		}
	}
}
.btn-cst-blue {
	background-color: #007ACC;
	padding: 4px 5px;
	color: #fff;
	font-size: 10px;
	font-weight: 300;
	border: unset;
	&:hover {
		background-color: #054C7B;
	}
	svg {
		vertical-align: sub;
	}
}
.btn-cst-grey {
	background-color: #fff;
	padding: 4px 5px;
	color: #292929;
	font-size: 10px;
	font-weight: 300;
	border: unset;
	&:hover {
		background-color: #ECECEC;
	}
}
.modal-header {
	padding: 10px;
}
.modal-title {
	line-height: 0;
	font-size: 16px;
	color: #292929;
	font-weight: 600;
	padding: 16px 10px;
	
}
.modal-small {
	width: 380px;
}
.cust-ttol {
	position: relative;
	display: inline-block;
	.tooltip-data {
		visibility: hidden;
		width: 300px;
		height: 220px;
		background-color: rgb(255, 255, 255);
		color: #292929;
		text-align: center;
		border-radius: 6px;
		padding: 5px 0;
		position: absolute;
		z-index: 1;
		bottom: 0;
		left: 75%;
		border: 1px solid #007ACC;
		top: 30%;
		text-align: left;
		
		font-size: 12px;
	}
	&:hover {
		.tooltip-data {
			visibility: visible;
		}
	}
}
.tooltip-data {
	h6 {
		padding-left: 10px;
		padding-bottom: 0px;
		font-weight: 600;
		
	}
	ul {
		list-style: none;
		padding-left: 5px;
		padding-right: 5px;
		font-size: 10px;
		color: #9E9E9E;
		
		font-weight: 200;
		li {
			padding: 5px;
			border-bottom: 1px solid #9e9e9e51;
			display: block;
			line-height: 16px;
		}
	}
}
.indicator-data {
	padding: 1px 3px;
	border-radius: 2px;
	color: #fff;
	margin-right: 4px;
	font-size: 10px;
	position: relative;
}
.green-span {
	background: #5EB281;
}
.blue-span {
	background-color: #509CCF;
}
.purple-span {
	background-color: #9E6FDC;
}
.red-span {
	background-color: #E86772;
}
.yellow-span {
	background-color: #D1973F;
}
.cust-padding {
	tr {
		td {
			padding: 10px;
			padding: 3px;
			vertical-align: middle;
			padding-left: 10px;
		}
	}
}
.diag-icon {
	.on-action {
		padding: 0px;
	}
}
.opt-data {
	padding: 10px;
}
option {
	min-height: 60px;
}
// .input-textarea {
// 	margin-bottom: 13px;
// 	height: 60px;
// 	font-weight: 400;
// 	&:focus {
// 		& + .label-text {
// 			top: -0.825rem;
// 			left: 0.538rem;
// 			z-index: 10;
// 			font-size: 12px;
// 			font-weight: 400;
// 			color: #007ACC;
// 			outline: none !important;
// 			box-shadow: none;
// 		}
// 	}
// 	&:not(:placeholder-shown) {
// 		& + .label-text {
// 			top: -0.825rem;
// 			left: 0.538rem;
// 			z-index: 10;
// 			font-size: 120px;
// 		}
// 	}
// 	label {
// 		position: absolute;
// 		top: -0.5rem!important;
// 		left: 0.538rem;
// 		padding: 0 4px;
// 		background-color: white;
// 		color: #9E9E9E;
// 		font-size: 12px;
// 		transition: 0.3s;
// 		z-index: 2;
// 	}
// }
.crt-pat-head {
	font-weight: 600;
	color: #2d2d2d;
	
	font-size: 2rem;
	padding-left: 13px;
}
.pr-info {
	font-family: 'Tahoma';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: #2D2D2D;
}
.line-after {
	position: relative;
	overflow: hidden;
	display: block;
	span {
		vertical-align: baseline;
		display: block;
		position: relative;
		&:after {
			content: '';
			position: absolute;
			width: 100%;
			top: 50%;
			margin-left: 10px;
			border-top: 0.5px solid rgba(45, 45, 45, 0.15);
		}
	}
}
.personal-info {
	padding-left: 16px;
	padding-bottom: 10px;
}
.facility-info, .insurance-policies-info, .kin-Patient-info, .diagnosis-info {
	padding-left: 16px;
	padding-bottom: 10px;
}
.create-patient-form {
	padding-top: 10px;
}
.crt-select {
	font-size: 14px;
	color: #292929;
	height: 45px;
	@media(max-width:1024px)
	{
		font-size: 11px;
	}
}
.radio-crtpt {
	margin-top: 6px;
}
.status-color {
	color: #292929;
}
.mt-top-20 {
	margin-top: 20px;
}
.mt-top-25 {
	margin-top: 27px;
}
.mt-top-10 {
	margin-top: 10px;
}
.insurance-info {
	display: flex;
}
.i-href {
	font-size: 12px;
	color: #292929;
	padding: 6px;
	margin-left: 3px;
	&:hover {
		color: #007ACC;
		background: #ECECEC;
	}
	svg {
		font-size: 19px;
		color: #007ACC;
		padding-right: 0px;
		vertical-align: bottom;
		margin-right: 5px;
	}
}
.primary-cont {
	color: #9E9E9E;
	font-size: 10px;
}
.footer-btn {
	display: flex;
	justify-content: flex-end;
	margin-left: auto;
	margin-right: 0;
}
.btn-grey {
	background-color: #fff;
	padding: 4px 5px;
	color: #292929;
	font-size: 13px;
	font-weight: 300;
	border: unset;
	text-align: right;
	margin-right: 10px;
}
.btn-blue {
	background-color: #007ACC;
	padding: 6px 20px;
	color: #ffffff;
	font-weight: 500;
    font-size: 16px;
    line-height: 26px;
	border: unset;
	
}
.sv-btn
{
	.btn-blue {
		&:hover{
			background: #054C7B;
		}
	}
}

.icon-inline {
	font-family: 'FontAwesome', 'tahoma';
}
.wit-90 {
	width: 90%;
}
.w-70 {
	width: 70%;
}
.wit-45 {
	width: 45%;
}
.mr-bt-35 {
	margin-bottom: 1.35rem!important;
}
:-ms-input-placeholder {
	color: #9E9E9E;
}
::-ms-input-placeholder {
	color: #9E9E9E;
}
.pd-right-45 {
	padding-right: 40px;
}
.padding-left-10px {
	padding-left: 25px;
}
.tab-full {
	margin-right: -25px;
}
option[value=""][disabled] {
	display: none;
}

.create-project
{
    .ad-textarea
    {
        border: 1px solid #9E9E9E; 
        border-radius: 0.325rem;
        height: 100px;
        width: 100%;
        outline: none;
        box-shadow: unset;
        font-size: 0.6rem;
        padding: 0.7rem 0.6rem;
        margin-bottom: 40px;
		label{
			transition: 0.3s;
		}
    }
    .inputtextarea 
    {
        height:100px;
    }
    .ad-textarea:focus + .label {
        top: -0.425rem;
        left: 0.538rem;
        z-index: 2;
        font-size: 12px;
        font-weight: 400;
        color: #007ACC!important;
        outline: none !important;
       box-shadow: none;
      }
      .ad-textarea:not(:placeholder-shown)+ .label {
        top: -0.425rem;
        left: 0.538rem;
        z-index: 2;
        font-size: 12px;
      }
      .ad-textarea:focus {
        border: 1px solid #007ACC;
        outline: none !important;
       box-shadow: none;
      }
	  .snd-btn
	  {
		background: #007ACC;
        border-radius: 10px;
		color:#fff;
		// width: 100%;
		border: 1px solid transparent;
		padding: 10px 40px;
		font-size: 16px;
		float: right;
		&:hover{
			background: #054C7B;
		}
		svg
		{
			font-size: 26px;
			margin-right: 5px;
			
		}
	  }
	  

}

.form-start
{
	.inputtextarea 
	{
		display: table;
		label
		{
			transition: 0.3ms;
		}
	}
	.ad-textarea
	{
		padding: 1em 0.75rem;
	}
	.ad-textarea:focus + .label {
        top: -0.5rem;
        left: 0.538rem;
        z-index: 2;
        font-size: 12px;
        font-weight: 400;
        color: #007ACC!important;
        outline: none !important;
       box-shadow: none;
      }
      .ad-textarea:not(:placeholder-shown)+ .label {
        top: -0.5rem;
        left: 0.538rem;
        z-index: 2;
        font-size: 12px;
      }
      .ad-textarea:focus {
        border: 1px solid #007ACC;
        outline: none !important;
       box-shadow: none;
      }
	  .cst-checkbox
	  {
		.form-check-ct
		{
			padding-bottom: 5px;
			.form-check-input
			{
				outline: none;
				box-shadow: none;
				&:checked {
					background-color: #007ACC;
					border-color: #007ACC;
					outline: none;
				box-shadow: none;
				}
			}
			label{
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 23px;
				color: #2D2D2D;
			}
		}
		
	  }
	  .frm-h
	  {
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 29px;
		color: #2D2D2D;
		padding-top:6px;
		padding-bottom: 6px;
	  }
	  .lin-hr
	  {
		border-top: 0.5px solid #D9D9D9;
		margin-bottom: 20px;
		margin-top: 20px;

	  }
	  
}
.vad-data-popup
	  {
		p{
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 30px;
			text-align: center;
			color: #2D2D2D;
		}
		.btn-cst-blue{
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 26px;
			color: #FFFFFF;
			border-radius: 5px;
		}
		.button-center
		{
			margin: auto;
			text-align: center;
		}
		.btn-cst-grey {
			font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 23px;

				color: #2D2D2D;

		}
	  }
.crt-project-title
{
	font-size: 24px;
color: #2D2D2D;
}
#createproject
{
	.modal-content{
		border-radius: 15px;
		padding: 12px;
	}
	.modal-header {
		padding: 0px 10px;
	}
}