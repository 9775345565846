@media (max-width:600px)
{
    body{
        overflow: scroll !important;
    }
    .login-pg {
         overflow: unset; 
    }
    .con-menu 
    {
        margin-top: 50px;
        width: unset;
        height: unset;
        .list-group-horizontal
        {
            flex-direction: column;
        }
        .list-group-item
        {
            .line-dt
            {
                &::after
                {
                    display: none;
                }
            }
        }
    }
    
    .logo-main {
        left: 40px!important;
        .logo-txt
    {
        display: none;
    }
    }
    .sm-task-list
    {
        .prj-nme
        {
            font-size: 9px;
            margin-left: 4px;
            padding-left: 7px;
        }
        .track-btn
        {
            font-size: 9px;
        }
        h4{
            font-size: 11px;
        }
    }
    .ft-right
    {
        span{
            font-size: 10px;
            padding-left: 4px;
        }
        .avatar-xs {
            width: 1.25rem;
            height: 1.25rem;
            font-size: 8px;
            .avatar-title{
                width: 1.25rem;
            }
        }
    }
    .summary-tab td {
        padding: 0.3rem 0.1rem;
    }
    .navbar-header 
    {
        .dropdown 
        {
            .dropdown-menu {
                left: unset !important;
            }
        }
    }
    .modal-small {
        width: unset;
    }
    .overview-header 
    {
        .prj-header 
        {
            h4 {
                font-size: 15px;
            }
        }
    }
    .prj-overview 
    {
        .last-item 
        {
            .fc-prf 
            {
                p {
                    width: 50%;
                }
            }
        }
    }
   
    .facility-details 
    {
        .ft-dt{
            P {
                font-size: 10px;
            }
            label {
                font-size: 10px;
            }
        }
    }
    .alc-dt 
    {
        label {
            font-size: 10px;
        }
        p{
            font-size: 12px;
        }
    }
    .pr-over 
    {
        .container-fluid
        {
            .overview-content {
                padding: 0 0 0 15px;
            }
        }
    }
    .prj-overview 
    {
        height: unset;
        .timeline-list-item 
        {
            .st-fnt {
                font-size: 14px;
            }
        }
    }
    .overview-header
    {
        .prj-header 
        {
            .pad-3 {
                margin-right: 0.6rem !important;
            }
        }
    }
    .pr-over {
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .validation-message {
        width: unset!important; 
    }
    .header-section 
    {
        h1 {
            font-size: 14px!important;
        }
       h4 {
        font-size: 13px!important;
       }
       .ct-header
        {
            .ct-left {
                width: 60%;
            }
            .ct-right
            {
                width: 40%;
                .log-out {
                  display: block;
                  padding-bottom: 20px;
                  a{
                    font-size: 10px;
                  }
                }
            }
        }
    }
    .cmp-data
    {
        .cus-icon {
            font-size: 12px;
        }
        svg{
            vertical-align: baseline;
        }
    }
    .form-menu 
    {
        .timeline-list-item{
            .nav-item 
            {
                .nav-link span {
                    font-size: 8px!important;
                }
            }
        }
        .timeline 
        {
            .nav-item {
                text-align: center;
                width: 16%;
            }
        }
    }
    
    .overview-content
    {
        .prj-overview
        {
            .col-md-4
            {
                width: 100%!important;
            }
        }
    }
    .pr-over 
    {
        .container-fluid {
            overflow-y: scroll!important;
            overflow-x: hidden!important;
           .overview-header
            {
                    .bt-btm
                    {
                        .col-md-1
                        {
                            width: 7%;
                            .ovr-data
                            {
                                width: 40px;
                                height: 40px;
                                border-radius: 5px;
                                 
                                    svg {
                                        color: #FFFFFF;
                                        font-size: 30px;
                                        margin: 6px 5px;
                                     }
                            }
                        }
                        .col-md-11
                        {
                            width: 93%;
                            .prj-header
                            {
                                .nav-link {
                                    font-size: 10px;

                                }
                            }
                        }
                    }
            }
        }
    }
    #ct-btn-id {
        top: -16px;  
    } 
    .tg-switch {
        margin-right: 16px;
         margin-left: auto;

    }
    .facility-details 
    {
        .prj-edit 
        {
            .dropdown-menu 
            {
                .create-project {
                    padding: 15px;
                    width: 215px;
                }
            }
        }
    }
    .crt-select {
        font-size: 10px;
    }
    .create-project
    {
        .form-control {
            font-size: 10px!important;
        }
    }
    #tsk-list 
    {
        .prj-list td {
            font-size: 6px;
            .dep-pr-title {
                font-size: 6px;
                svg
                {
                    width:10%;
                }
            }
        }
        .prj-btn {
            font-size: 6px;
        }
    }
    .list-task-tb
    {
        .prj-btn svg {
            margin-right: 0px;
        }
        .wit-7,.wit-20,.wit-30
        {
            width: unset;
        }
    }
    .task-content .left-content .list-task-tb td svg {
        font-size: 18px;
        margin-left: 5px;
    }
    .task-content 
    {
        .left-content 
        {
            .list-task-tb 
            {
                td 
                {
                    svg {
                        font-size: 6px;
                            margin-left: 0px;
                        
                    }
                }
            }
            .flex-data 
            {
                span {
                    padding-left: 2px;
                }
                .avatar-xs {
                    font-size: 6px;
                    width: 1rem;
                    height: 1rem;
                  .bg-soft,.bg-orange,.bg-litgreen {
                        width: 1rem;
                    }
                }
            }
        }
    }
    .task-tbl
    {
        .list-task-tb
        {
            th{
                font-size: 8px;
            }
        }
    }
    .doc-custom
    {
        .container-fluid
        {
            .ps-fixed
            {
                position: fixed!important;
                margin-top: 50px;
                width: 100%;
            }
        }
    }
    #contentleft-data
    {
        padding-left: 8px;
    padding-right: 12px;
        .prj-list
        {
            p{
                padding-top: 30px;
            }
        }
    }
    .task-content {
        padding: 8px;
    }
    .form-main 
    {
        .thks-data {
            width: unset!important;
            margin: unset;
        }
    }
    .wt-70 {
        width: 50%;
    }
    .custom-accordion-documents 
    {
        #document-accordian 
        {
            .accordion-header 
            {
                .accordion-button {
                    font-size: 9px;
                }
               .document-count {
                right: 0%;
                width: 22%;
                font-size: 9px;
                top: -6px;
                }
            }
        }
        .action-btn
        {
            button 
            {
                svg {
                    font-size: 12px!important;
                }
            }
        }
    }
    .task-content 
    {
        .doc-table
        {
            .list-task-tb 
            {
                td {
                    font-size: 8px;
                    padding: 8px;
                }
            }
        }
    }
    .fc 
    {
        .fc-col-header-cell-cushion {
            font-size: 8px;
            padding: 2px 2px;
        }
        .fc .fc-daygrid-day-number {
            padding: 2px;
            font-size: 10px;
        }
    }
    .wt-14-2 {
        width: 47.28%!important;
    }
    .sl-plc-holder {
        margin-bottom: 25px;
    }
    .event-styles {
    font-size: 5px!important;
}
.create-task 
{
    .ad-task
    {
        .add-dependent 
        {
            .dep-info 
            {
                .dropdown-menu-lg {
                    width: 257px;
                    right: -100px !important;
                    border-radius: 14px;
                }
            }
        }
    }
}
.pagination-container {
    top: 80px;
}
.prj-progressbar
{
    display: block;
    .pgr {
        font-size: 10px;
    }
   .progress {
        margin-right: 2px;
        height: 7px;
    }
}
.active-status
{
    display: block !important;
}

.hd-right
{
    .btn-close
    {
        float: right;
        margin-top: -34px;
        margin-left: 15px;
    } 
}
}


@media(max-width:1024px)
{
    .document-data {
        padding: 45px;
    }
    #contentleft-data {
     overflow-y: scroll; 
    }
    .pr-over 
    {
        .container-fluid {
           overflow: scroll;
           .overview-header
            {
               
                    padding: 20px 0px 0px 6px;
                    position: sticky;
                    top: 0;
                    z-index: 999;
                    background: #fff;
                    border-bottom: 1px solid #a3a3a338;
                    .bt-btm
                    {
                        .col-md-1
                        {
                            width: 7%;
                            .ovr-data
                            {
                                width: 40px;
                                height: 40px;
                                border-radius: 5px;
                                 
                                    svg {
                                        color: #FFFFFF;
                                        font-size: 30px;
                                        margin: 6px 5px;
                                     }
                            }
                        }
                        .col-md-11
                        {
                            width: 93%;
                            .prj-header
                            {
                                .nav-link {
                                    font-size: 10px;

                                }
                            }
                        }
                    }
            }
        }
    }
    .doc-custom
    {
        .container-fluid
        {
            overflow: scroll;
        }
       .document-customheader
       {
        h3 {
            font-size: 20px;
        }
       }
    }
    .event-styles {
        font-size: 8px !important;
    }
    .event-container
    {
        .avatar-xs {
            width: 0.8rem;
            height: 0.8rem;
            font-size: 6px;
            margin-right: 5px !important;
        }
    }
    .event-container
    {
        .avatar-xs
        {
            .bg-soft,.bg-litgreen,.bg-orange {
                width: 0.8rem;
            }
        }
    }
    .logo-main {
        left: 70px;
    }
    #tsk-list
    {
        .prj-list 
        {
            td 
            {
                .dep-pr-title 
                {
                    svg {
                        width: 10%;
                    }
                }
            }
        }
    }
    .sm-task-list 
    {
        .track-btn {
            font-size: 9px;
        }
        h4 {
            font-size: 10px;
        }
    }
    .ft-right
    {
        .flex-data 
        {
            .avatar-xs {
                width: 1rem;
                height: 1rem;
                font-size: 10px;
                .bg-soft, .bg-litgreen, .bg-orange {
              
                    width: 1rem;
                }
            }
            span {
                padding-top: 0px;
                padding-left: 5px;
                font-size: 10px;
            }
        }
        .lab-dt-overdue {
            font-size: 10px;
        }
        .lab-dt {
            font-size: 10px;
        }
    }
    .pagination-container {
        top: 80px;
    }
    .sticky-header
{
    span{
        font-size: 10px;
        padding-left: 4px;
    }
    .avatar-xs {
        width: 1.25rem;
        height: 1.25rem;
        font-size: 8px;
        .avatar-title{
            width: 1.25rem;
        }
    }
    .cr-hed
    {
        margin-bottom: 20px;
        p, .prj-btn {
            font-size: 10px;
        }
    }
}

}


// Calendar style issues

@media(max-width:1024px)
{
    .event-styles 
    {
        font-size: 8px!important;
        padding: 3px!important;
    }
    .event-title-container{
        width: (100% - 10%) !important;
        text-overflow: ellipsis !important;
        overflow: hidden !important;
    }
    .event-content{
        text-overflow: ellipsis !important;
        overflow: hidden !important;
    }
    .moreLinkClassNames{
        font-size: 10px !important;
        height: 14px !important;
        padding: 2px !important;
    }
   
}

@media(max-width:1200px)
{
    .wt-14-2 {
        width: 20%;
    }
}