
.contentw-wrapper {
    .page-content {
        padding: calc(50px + 14px) calc(24px * 0.75) 60px calc(24px * 0.75);
        background-color: #fff;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 50px;

        // overflow: hidden;
        .top-bg {
            background: #007acc;

            .top-nav {
                padding: 14px 0px 110px 15px;

                svg {
                    margin-bottom: 20px;
                }

                h1 {
                    color: #fff;
                    font-size: 40px;
                }
            }
        }
    }

    .bg-white {
        min-height: 400px;
        background: #ffffff;
        box-shadow: 0px 512px 205px rgba(0, 0, 0, 0.01), 0px 288px 173px rgba(0, 0, 0, 0.05),
            0px 128px 128px rgba(0, 0, 0, 0.09), 0px 32px 70px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
        border-radius: 16.1806px;
        margin-top: -89px;
        padding: 40px;
    }
}

.pr-slider {
    padding: 30px;
    min-height: 430px;
    border: 1px solid #d9d9d9;
    border-radius: 16.1806px;

    img {
        width: 100%;
    }
}

.overview-header {

    .ovr-data {
        width: 60px;
        height: 60px;
        padding: 0px;
        margin: 0;
        border-radius: 20px;
        background: #9747FF;

        svg {
            color: #FFFFFF;
            font-size: 30px;
            margin: 15px 15px;
        }
    }

    .prj-header {
        margin-left: -40px;

        @media(max-width:1290px) {
            margin-left: 0px;
        }

        .nav-pills .nav-link.active,
        .nav-pills .show>.nav-link {
            color: #007ACC;
            background-color: #fff;
            border-bottom: 3px solid #007ACC;
            border-radius: 0px;
            padding: 0px;
            padding-bottom: 0px;
            margin-bottom: -1.5px;
            padding-top: 8px;
            padding-left: 0px;
            padding-right: 0px;
        }

        .nav-link {
            padding-top: 0px !important;
        }

        .nav-item {
            margin-top: 10px;
        }

        .pad-3 {
            margin-right: 1.5rem !important;
        }

        h4 {

            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 25px;
            color: #2D2D2D;

            @media(max-width:1024px) {
                font-size: 26px;
            }
        }

        .badge-soft-grey {
            color: #005D85;
            ;
            background: #DEE3E6;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            padding: 6px;
            vertical-align: bottom;
        }
    }




}


.prj-overview {
    padding-top: 20px;
    height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 10px;

    .snd-btn {
        background: #007ACC;
        border-radius: 10px;
        color: #fff;
        width: 225px;
        border: 1px solid transparent;
        padding: 5px 5px;
        font-size: 16px;
        margin-top: 10px;
    }

    .activity-wid {
        margin-left: 16px;

        .activity-border {
            &:before {
                content: "";
                position: absolute;
                height: 55px;
                border-left: 2px dashed var(--bs-gray-400);
                top: 55px;
                left: 11px;
            }
        }

        .activity-list {
            position: relative;
            padding: 0 0 45px 45px;

            .activity-icon {
                position: absolute;
                left: -14px;
                top: -3px;
                z-index: 2;
                background: var(--bs-card-bg);
                width: 61px;
                height: 61px;
                border: 2px dashed var(--bs-gray-400);
                border-radius: 50%;

                span {
                    border: 5px solid var(--bs-card-bg);
                    background-color: #fff;

                    svg {
                        font-size: 30px;
                        color: #2D2D2D;
                    }
                }
            }
        }
    }

    .timeline-list-item {
        margin-left: 20px;
        margin-top: 5px;

        .st-fnt {

            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 23px;
            color: #2D2D2D;

        }

        .srt-onboarding {
            background: #007ACC;
            border-radius: 10px;
            color: #fff;
            width: 100%;
            padding: 4px 20px;

            &:hover {
                background: #054C7B;
            }
        }

        .snd-btn {
            svg {
                margin-right: 5px;
            }

            &:hover {
                background: #054C7B;

            }
        }
    }

    .last-item {
        .fc-prf {
            p {
                color: #A3A3A3;
                width: 20%;
            }

            .pad-lf-3 {
                padding-left: 40px;
            }
        }

        .br-btm {
            border-bottom: 0.01rem solid #a3a3a331;
            margin-bottom: 20px;
        }

        .action-btn {

            text-align: center;

            button {
                background: transparent;
                border: 0px;

                svg {
                    color: #A3A3A3;
                    font-size: 20px;

                    &:hover {
                        color: #007ACC;
                    }
                }
            }


        }

        .dwn-btn {
            a {

                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 23px;
                padding-right: 20px;
                color: #2D2D2D;
                margin-top: 5px;
            }
        }

    }

    #act-text {
        display: none;
    }

    #last-review {
        display: none;
    }
}

.prj-overview-right {
    background: #F7F8F9;
    // border: 0.5px solid #D9D9D9;
    height: 80vh;
    overflow-y: scroll;

    .card-view {
        padding: 10px 40px;

        .card {
            box-shadow: 14px 10px 7px rgba(0, 0, 0, 0.01), 8px 6px 6px rgba(0, 0, 0, 0.03), 3px 2px 4px rgba(0, 0, 0, 0.04), 1px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05);
            border-radius: 15px;

            .card-body {
                padding: 20px;
                min-height: 150px;

                .grn-btn {
                    padding: 3px 6px;
                    border: 2px solid green;
                    width: 38%;
                    border-radius: 6px;
                    font-size: 12px;

                    @media (max-width:1024px) {
                        width: 38%;
                    }

                    svg {
                        color: green;
                        margin-right: 5px;
                        font-size: 18px;
                    }
                }
                .red-btn {
                    padding: 3px 6px;
                    border: 2px solid #E2435C;
                    width: 38%;
                    border-radius: 6px;
                    font-size: 12px;

                    @media (max-width:1024px) {
                        width: 38%;
                    }

                    svg {
                        color: #E2435C;
                        margin-right: 5px;
                        font-size: 18px;
                    }
                }
                .yel-btn {
                    padding: 3px 6px;
                    border: 2px solid #CF8A08;
                    width: 38%;
                    border-radius: 6px;
                    font-size: 12px;

                    @media (max-width:1024px) {
                        width: 38%;
                    }

                    svg {
                        color: #CF8A08;
                        margin-right: 5px;
                        font-size: 18px;
                    }
                }

                .ble-btn {
                    padding: 6px 12px;
                    border: 2px solid #007ACC;
                    width: 54%;
                    border-radius: 6px;
                    font-size: 12px;

                    @media(max-width:1024px) {
                        width: 66% !important;
                    }

                    svg {
                        color: #007ACC;
                        margin-right: 5px;
                        font-size: 20px;
                    }
                }

                .text-center {
                    padding-top: 10px;

                    h3 {
                        font-size: 16px;
                    }
                }

                .card-title {

                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 23px;
                    color: #000;

                    span {
                        font-size: 12px;
                        text-align: right;
                        float: right;
                        color: #a3a3a3;

                        @media(max-width:1024px) {
                            display: block;
                            float: unset;
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}

.overview-header {
    border-bottom: 0px solid #9e9e9e36;

    .bt-btm {

        padding: 0px;
    }

    .header-item {
        height: 52px;
        width: 126px;

    }
}

.pr-over {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 50px;
    padding-bottom: 0px;
    overflow-y: unset;

    .tab-content {
        height: 100%;
        // overflow: auto;
    }

    .container-fluid {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
        padding-right: 0px;
        height: 100%;

        .overview-header {
            padding: 20px 0px 0px 28px;
            position: sticky;
            top: 0;
            z-index: 999;
            background: #fff;
            border-bottom: 1px solid #a3a3a338;

        }

        .overview-content {
            padding: 0px 0px 0px 35px;
            height: 100%;

            // overflow: scroll;
            .row {
                height: 100%;
            }
        }
    }
}

.document-view {

    // padding-bottom: 160px;
    // height: 500px;
    // overflow-y: scroll;
    // margin-top: -40px;
    .nav-menu-data {
        // padding: 15px;
        // padding-top: 10px;  
        padding-left: 0px;
        position: sticky;
        top: 0;

        p {

            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 23px;
            color: #2D2D2D;
        }

        .navbar {
            ul {
                list-style: none;
                padding-left: 0px;
            }
        }

    }

    .finances-data {
        margin-right: 40px;
        margin-top: 10px;
        // height: 450px;
        overflow-y: scroll;

        h4 {

            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 23px;

            color: #2D2D2D;
        }

        .tab-fin {
            .fin-table {
                border-collapse: collapse;
                border-top-left-radius: 1em;
                border-top-right-radius: 1em;
                overflow: hidden;

                thead {
                    background: #C9E9FF;
                    border-top-left-radius: 15px;
                    border-top-right-radius: 15px;
                }

                .fin-tb-body {
                    border: 0.5px solid #D9D9D9;
                    border-radius: 6px;

                    .wt-preline {
                        white-space: pre-line;
                    }
                }
            }
        }
    }
}

.last-item {
    .rv-fac {
        flex-grow: 0.1;
    }

    .view-bt {
        margin-top: 10px;

        a {

            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 23px;
            color: #A3A3A3;
            padding-right: 20px;

            svg {
                font-size: 20px;
            }
        }

    }
}

@media (max-width:1024px) {
    .overview-header {
        .prj-header {
            margin-left: 20px;
        }
    }

    .document-view {
        .nav-menu-data {
            padding-left: 0px;

        }

        .finances-data {
            margin-right: 0px;
        }
    }

    .main-dashboard {
        .prj-overview {
            a {
                h2 {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 23px;
                    color: #2D2D2D;
                    margin-left: 10px;
                }
            }
        }
    }
}

@media (max-width:768px) {
    .document-view {
        .nav-menu-data {
            padding-left: 0px;

        }

        .finances-data {
            margin-right: 0px;
        }
    }

    .main-dashboard {
        .prj-overview {
            a {
                h2 {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 23px;
                    color: #2D2D2D;
                    margin-left: 10px;
                }
            }
        }
    }
}

.suc-modal-content {
    border-radius: 20px;

    .successfull-message {
        .success-icon {
            svg {
                font-size: 55px;
                color: #1E823A;
            }
        }

        .success-header {
            padding: 15px;

            h3 {

                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 28px;
                text-align: center;
                color: #000000;
            }

            p {

                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #5A5A5A;
                padding: 10px;

            }

            .success-btn {
                background: #007ACC;
                padding: 8px 40px;

                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 26px;
                color: #FFFFFF;
                border-radius: 10px;
                border: #007ACC;
            }
        }
    }
}

.tasklist-header {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #9e9e9e36;
    padding-left: 8px;

    .i-href-task {

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 23px;
        border-radius: 10px;
        padding: 6px 10px;
        color: #2D2D2D;
        margin-right: 30px;
        border: unset;
        background: unset;

        svg {
            font-size: 20px;
            vertical-align: sub;
            margin-right: 0px;
        }

        &:hover {
            color: #007ACC;
            background: #ECECEC;
        }
    }

    .i-href-task-blue {

        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 23px;
        border-radius: 10px;
        padding: 6px 10px;
        color: #fff;
        margin-right: 30px;
        border: unset;
        background: #007ACC;

        svg {
            font-size: 16px;
            vertical-align: sub;
            margin-right: 0px;
        }

        &:hover {
            color: #fff;
            background: #054C7B;
        }

    }

}

#pt-task {
    .overview-content {
        border-bottom: 1px solid #9e9e9e36;

    }

    .left-span,
    .right-span {
        svg {
            font-size: 20px;
        }
    }
}

#colright-data, #contentright-data
{
    // display: none;
    border-left: 1px solid #9e9e9e36;

    .tsk-icons {
        float: right;
        font-size: 20px;

        a {
            padding-right: 4px;
            cursor: pointer;
            color: #292929;

            &:hover {
                color: #007ACC;
                text-decoration: underline !important;
            }
        }
    }
}
#contentright-data
{
    // display: none;
    border-left: 1px solid #9e9e9e36;
    height: 80vh;
    overflow-y: scroll;
    padding-bottom: 45px;
    box-shadow: -6px 63px 25px rgba(0, 0, 0, 0.01), -3px 35px 21px rgba(0, 0, 0, 0.05), -1px 16px 16px rgba(0, 0, 0, 0.09), 0px 4px 9px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    // @media(max-width:1024px)
    // {
    //     height: 800px;
    //     overflow-y: scroll;
    // }
    .create-project {
        padding: 10px 10px 10px;
        padding-top: 0px;
        /* position: fixed; */
        margin-top: 4px;

        .btn-cst-grey {

            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 26px;
            color: #2D2D2D;
            padding: 6px 10px;
            vertical-align: middle;

            svg {
                margin-right: 3px;
                font-size: 16px;
                vertical-align: sub;
            }

            &:hover {
                color: #007ACC;
                border-radius: 10px;
            }
        }

        .date-icon {
            top: 16px
        }

        .select-tab {
            .dropdown {
                .dropdown-menu {
                    margin: 32px !important;
                }

                a {
                    svg {
                        position: relative;
                        float: right;
                        font-size: 16px;
                        margin-right: 20px;
                        margin-top: 18px;
                        vertical-align: middle;
                        z-index: 999;
                        cursor: pointer;
                    }
                }

            }
        }
    }
}

.task-content {
    padding: 15px;
    padding-top: 0px;
    height: 100%;

    .left-content {
        height: 100%;
        padding-bottom: 100px;
        overflow: hidden;

        .list-task-tb {

            th{
                vertical-align: initial;
                color: #A3A3A3;
                @media(max-width:1024px)
                {
                    padding: 8px 6px;
                }
            }
            td{
                vertical-align: initial;
                color: #2d2d2d;
            }

            .dt-data {
                color: #292929;

                .btn-group {
                    float: right;
                }
            }

            .current-data {
                color: #249B45;
            }

            .blue-data {
                color: #007ACC;
            }

            td {

                .act-dt {
                    color: #249B45;
                }

                svg {
                    // color: #292929;
                    font-size: 18px;
                    margin-right: 5px;
                    @media(max-width:1024px)
                    {
                        font-size: 10px;
                    }
                }
            }
        }

        .flex-data {
            display: flex;

            .avatar-xs {
                width: 1.75rem;
                height: 1.75rem;
                font-size: 12px;

                .bg-soft {
                    background-color: #4397FF !important;
                    color: #fff !important;
                    width: 1.75rem;
                }

                .bg-orange {
                    background-color: #FF8839 !important;
                    color: #fff !important;
                    width: 1.75rem;
                }

                .bg-litgreen {
                    background-color: #9CC5A0 !important;
                    color: #fff !important;
                    width: 1.75rem;
                }
            }

            span {
                padding-top: 4px;
                padding-left: 10px;
            }
        }

        .wit-40 {
            width: 40%;
        }

        .wit-25 {
            width: 25%;
        }

        .wit-10
        {
            width: 10%;
        }
        .wit-7{
            width: 7%;
            @media(max-width:1024px)
            {
                width: 10%;
            }
        }
        .wit-30{
            width: 30%;
            @media(max-width:1024px)
            {
                width: 32%;
            }
        }
    }
    .con-rit
    {
        width: 41.6666%;
        @media(min-width:768px)
        {
            width: 40.666%;
        }
    }
}

.custom-accordion {
    .accordion-item {
        color: #292929;
        background-color: transparent;
        border: unset;
    }

    .accordion {
        border: unset;

    }

    .accordion-button {

        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 35px;
        border-bottom: 1px solid #9e9e9e36;
        padding: 12px;
        /* identical to box height */


        /* CLHF Blac */

        color: #2D2D2D;

        &:after {
            margin-left: 30px;
        }
    }

    .accordion-button:not(.collapsed) {
        background-color: transparent;
        border-bottom: 0px;
        box-shadow: inset 0 calc(var(--bs-accordion-border-width) * -0.4) 0 var(--bs-accordion-border-color);
    }

    .accordion-body {
        padding: 0px;

        .table {
            margin-bottom: 0px;
        }
    }
}

.br-left {
    border-left: 1px solid #9e9e9e36;
}

.task-header {
    padding: 0px 15px;
    position: sticky;
    padding-top: 0px;
    top: 0px;
    background-color: #fff;
    z-index: 99;

    .wit-40 {
        width: 40%;
    }

    .wit-25 {
        width: 25%;
    }

    .wit-10 {
        width: 12%;
    }

}

.task-content {
    height: 100%;
    overflow: hidden;

    .dropdown {
        .dropdown-menu .show {
            top: 100% !important;
        }
    }
}

#colleft-data {
    table {
        margin-bottom: 0px;
    }

    .tasklist-header {
        padding-left: 10px;
    }
}

.rd {
    color: #d70015;

    svg {
        color: #d70015 !important;
    }

    &:hover {
        color: #d70015;
    }
}

.selected {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}

.dismiss {
    animation: slide-out 1.5s forwards;
    -webkit-animation: slide-out 1.5s forwards;
}

@keyframes slide-in {
    0% {
        -webkit-transform: translateX(100%);
    }

    100% {
        -webkit-transform: translateX(0%);
    }
}

@-webkit-keyframes slide-in {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0%);
    }
}

@keyframes slide-out {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(100%);
    }
}

@-webkit-keyframes slide-out {
    0% {
        -webkit-transform: translateX(0%);
    }

    100% {
        -webkit-transform: translateX(100%);
    }
}

#contentleft-data {
    height: 80vh;
    overflow-y: scroll;
    padding-bottom: 45px;
    overflow-x: hidden;
    // @media(max-width:1024px)
    // {
    //     height: 800px;
    //     overflow-y: scroll;
    // }
}

.grey {

    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;

    color: #A3A3A3;

    &:hover {
        color: #007ACC;
    }

}

.task-tbl {
    thead {
        th {
            font-weight: 400 !important;
            color: #A3A3A3;
        }
    }

    .br-icon {
        text-align: center;

        svg {
            font-size: 20px;
        }
    }
}

.tg-switch {
    margin-left: auto;
    width: 80px;
}

.facility-details {
    padding-bottom: 10px;
    border-bottom: 0.01rem solid #a3a3a331;
    margin-bottom: 20px;

    .ft-mn {
        margin-bottom: 0.4rem;

        h4 {
            width: 100%;
        }

        .ft-mn-rt {
            .header-item {
                height: 40px;
            }

            .bordered {
                border: 1px solid #A3A3A3;
                box-shadow: unset;
                border-radius: 10px;
            }
        }
    }

    .ft-dt {
        padding-left: 10px;

        label {
            margin-bottom: 0px;
            color: #A3A3A3;
        }

        P {
            color: #2D2D2D;
            font-size: 14px;
        }
    }

    .prj-edit {
        svg {
            font-size: 26px;
            color: #2D2D2D;
            margin-top: 0px;
        }
    }

    .prj-edit {
        .dropdown-menu {
            border-radius: 15px;

            .prt-oview {
                padding: 10px;
            }

            .create-project {
                padding: 20px;
                background: #FFFFFF;
                border-radius: 15px;

                .ad-textarea {
                    border: 1px solid #9E9E9E;
                    border-radius: 0.325rem;
                    height: 100px;
                    width: 100%;
                    outline: none;
                    box-shadow: unset;
                    font-size: 0.6rem;
                    padding: 0.7rem 0.6rem;
                    margin-bottom: 40px;

                    &:focus {
                        border: 1px solid #007ACC;
                        outline: none !important;
                        box-shadow: none;

                        &+.label {
                            top: -0.5rem;
                            left: 0.538rem;
                            z-index: 2;
                            font-size: 12px;
                            font-weight: 400;
                            color: #007ACC;
                            outline: none !important;
                            box-shadow: none;
                        }
                    }

                    &:not(:placeholder-shown) {
                        &+.label {
                            top: -0.5rem;
                            left: 0.538rem;
                            z-index: 2;
                            font-size: 12px;
                        }
                    }

                }

                .inputformContainer {
                    position: relative;
                    height: 45px;
                    width: 100%;
                    margin-bottom: 1.2rem;
                    color: #9E9E9E;

                    label {
                        position: absolute;
                        top: 0.7rem;
                        left: 0.538rem;
                        padding: 0 4px;
                        background-color: white;
                        color: #9E9E9E;
                        font-size: 0.6rem;
                        transition: 0.3s;
                        z-index: 0;
                        font-weight: 400;
                        font-size: 14px;
                        // line-height: 23px;
                    }
                }

                .inputformdata {
                    position: absolute;
                    top: 0rem;
                    left: 0rem;
                    height: 100%;
                    width: 100%;
                    border: 1px solid #9E9E9E;
                    border-radius: 0.325rem;
                    font-size: 0.6rem;
                    padding: 0.225rem 0.6rem;
                    background: none;
                    z-index: 1;
                    outline: none !important;
                    box-shadow: none;

                    &:focus {
                        border: 1px solid #007ACC;
                        outline: none !important;
                        box-shadow: none;

                        &+.label {
                            top: -0.5rem;
                            left: 0.538rem;
                            z-index: 2;
                            font-size: 12px;
                            font-weight: 400;
                            color: #007ACC;
                            outline: none !important;
                            box-shadow: none;
                        }
                    }

                    &:not(:placeholder-shown) {
                        &+.label {
                            top: -0.5rem;
                            left: 0.538rem;
                            z-index: 2;
                            font-size: 12px;
                        }
                    }
                }

                .form-select {
                    height: 100%;
                    width: 100%;
                    border: 1px solid #9E9E9E;
                    border-radius: 0.325rem;
                    font-size: 0.6rem;
                    padding: 0.225rem 0.6rem;
                }

                ::placeholder {
                    color: #9e9e9ebb;
                    font-size: 12px;
                    font-weight: 500;
                    color: #9E9E9E;
                    opacity: 1;
                    font-size: 12px;
                }

                .btn-project-create {
                    margin-top: 50px;

                    .cst-blue {
                        border-radius: 10px;

                        svg {

                            margin-top: 0px;
                            color: #fff;
                        }

                        &:hover {
                            background: #054C7B;
                        }
                    }

                    .cst-gry {
                        padding: 6px 12px;
                        color: #292929;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 26px;
                        border: unset;

                        svg {

                            margin-top: 0px;
                            color: #292929;
                            ;
                        }
                    }
                }

            }
        }

    }
}

.tab-content>.active {
    outline: none !important;
}

.cls-rd {
    color: #A3A3A3;

    svg {
        margin-right: 5px;
    }
}

.fc-doc {
    color: #000000;
}

.ct-btn {
    background: #007ACC;
    border-radius: 10px;
    color: #fff;
    width: 150px;
    border: 1px solid transparent;
    padding: 5px 5px;
    font-size: 14px;
    margin-top: 12px;
}

.verti-timeline {
    border-left: 3px dashed var(--bs-gray-300);
    margin: 0 10px
}

.verti-timeline .event-list {
    position: relative;
    padding: 0 0 40px 30px
}

.verti-timeline .event-list .event-timeline-dot {
    position: absolute;
    left: -18px;
    top: 0;
    z-index: 9;
    font-size: 16px
}

.verti-timeline .event-list .event-content {
    position: relative;
    border: 2px solid var(--bs-border-color);
    border-radius: 7px
}

.verti-timeline .event-list.active .event-timeline-dot {
    color: #556ee6
}

.verti-timeline .event-list:last-child {
    padding-bottom: 0
}

.projects {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 50px;
    padding-bottom: 0px;
    overflow-y: scroll;
    overflow-x: hidden;

    @media(max-width:1024px) {
        overflow: hidden;
    }

    .overview-header {
        .prj-header {
            margin-left: 0px;
        }
    }

    // .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    //     font-weight: 600;
    // }
    .tasklist-header {
        .i-href-task {
            font-size: 14px;
            color: #000;

            svg {
                font-size: 16px;
            }

            &:hover {
                color: #007ACC;
            }
        }
    }

    .prj-list {
        td {
            padding: 0.5rem;
        }
    }


}

.prj-btn{
    padding: 8px 0px;
    font-size: 12px;
    svg{
        margin-right: 5px;
        font-size: 20px;
        @media(max-width:1024px)
            {
                font-size: 14px;
            }
    }
    @media(max-width:1024px)
            {
                // font-size: 7px;
                font-size: 10px;
            }
}

.grn-btn{
    svg{
        color: #1E823A;
    }
}
.ble-btn{
    svg{
        color: #007ACC;
    }
}
.yel-btn
{
    svg{
        color:#CF8A08;
    }
}
.red-btn
{
    svg{
        color:#E2435C;
    }
}
.wit-15 {
    width: 15%;
}

.wit-20{
    width: 20%;
    @media(max-width:1024px)
    {
        width: 22%;
    }
}

#project-list
{
.wit-20
{
    @media(max-width:1024px)
    {
        width: 20%;
    }
}
.wit-18
{
    @media(max-width:1024px)
    {
        width: 18%;
    }
}
.wit-16{
    width: 16%;
}
.wit-14{
    width: 14.5%;
}
}

.prj-progressbar {
    display: flex;
    margin-top: 10px;

    span {
        padding-right: 10px;
    }

    .progress {
        width: 70%;
        margin-top: 5px;
        border-radius: 20px;

        .bg-green {
            background: #1E823A;
        }

        .bg-blue {
            background: #007ACC;
        }

        .bg-yellow {
            background: #CF8A08;
        }
    }
}

.green {
    color: #1E823A;
}

.blue {
    color: #007ACC;
}

.yellow {
    color: #CF8A08;
}

.mt-8-tp {
    margin-top: 8px;
}

.prj-list td a {
    color: #000;
}

.wit-97 {
    width: 97%;
}

.pr-list {
    height: 100%;
    overflow: auto;

    .page-item {
        cursor: pointer;

    }

    .page-item.previous {
        svg {
            margin-left: 7px;
        }

    }

    .page-item.next {
        svg {
            margin-left: 0px;
        }

    }

    .page-item:first-child .page-link {
        padding: 7px 6px !important;
    }

    .page-item:last-child .page-link {
        border-radius: 50%;
        padding: 7px 10px;
        outline: none;
        box-shadow: none;
    }

    table.dataTable>tbody>tr {
        background-color: #fff;
    }

    .view-pager {
        margin-top: 20px;
    }

    table.dataTable thead th,
    table.dataTable thead td,
    table.dataTable tfoot th,
    table.dataTable tfoot td {
        padding: 0.75rem 0.5rem;
    }

    .prj-list 
    {
        td {
            padding: 0.2rem 0.5rem!important;
            @media(max-width:1200px)
            {
                padding: 4px 5px!important;
            }
        }
    }
}

.mrk-cmp-btn {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 23px;
    border-radius: 10px;
    padding: 6px 10px;
    color: #000;
    margin-right: 30px;
    border: 1px solid #000;

    background: #fff;

    &:hover {
        background: #CAFFD9;
        color: #1E823A;
        border: 1px solid #1E823A;
    }
}

.kb-dt {
    color: #a3a3a3;
    padding-left: 10px;
}

.mtop-30 {
    margin-top: 30px;
}

.attachment,
.addattachment,
.viewattachment,
.addattachmentdata,
.viewhistory {
    .bc-attachment {
        color: #000;
        font-size: 16px;
        vertical-align: middle;

        svg {
            font-size: 22px;
            margin-right: 10px;
        }
    }
}

.attach-tbl {
    .list-task-tb {
        a {
            color: #007ACC;
            text-decoration: underline !important;

            &:hover {
                color: #007ACC;
            }

            span {
                text-decoration: none !important;
            }
        }
    }
}

.addattachment {
    .browse-file {
        background-color: #F5FAFF;
        padding: 30px 20px;
        border-radius: 5px;

        .cloud-upload {
            width: 50%;
            position: relative;
            color: #a3a3a3;
            cursor: pointer;

            svg {
                font-size: 70px;

            }

            a {
                display: block;
            }

            &:after {
                content: "";
                height: 90px;
                width: 0.02px;
                background: black;
                top: 10px;
                bottom: 0;
                margin-left: 10px;
                border: 0.1px solid #007ACC;
                position: absolute;
                right: 0px // left: 37%;
            }

            &:hover {
                color: #007ACC;
            }
        }

        .device-upload {
            width: 50%;
            position: relative;
            color: #a3a3a3;
            cursor: pointer;

            svg {
                font-size: 70px;
            }

            a {
                display: block;
            }

            &:hover {
                color: #007ACC;
            }
        }

    }
}

.viewattachment {
    .view-file {
        background-color: #F5FAFF;
        padding: 30px 20px;
        border-radius: 5px;

        svg {
            margin-bottom: 10px;
            color: #007ACC;
        }

        a {
            text-decoration: underline !important;
        }

        .remove-file {
            color: #D64D55;
            display: flex;
            margin-top: 6px;
            margin-bottom: 10px;

            svg {
                margin-right: 5px;
                color: #D64D55;
                margin-top: 3px;
            }
        }

        .file-uploaded {
            display: flex;
            color: #a3a3a3;
            font-size: 16px;

            svg {
                font-size: 24px;
                color: #a3a3a3;
                margin-left: 10px;
            }
        }

    }
    .ct-btn {
        width: auto;
        border: 1px solid transparent;
        padding: 6px 10px;

    }

    .mtop-10 {
        margin-top: 10px;
    }
}

.addattachmentdata {

    .ad-attach-data {
        text-align: center;
        color: #a3a3a3;

        svg {
            font-size: 80px;
            color: #D9D9D9;
        }

        button {
            svg {
                color: #fff;
            }
        }
    }
}

.cont-data-at {
    width: 300px;
    height: 450px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.viewhistory {
    .history-date {
        .dt-his {
            color: #a3a3a3;
            position: relative;
            width: 100%;
            display: block;

            &:after {
                content: '';
                position: absolute;
                width: 98%;
                top: 50%;
                margin-left: 10px;
                border-top: 0.5px solid rgba(45, 45, 45, 0.15);
            }
        }

        .history-content {
            .green {
                color: #249B45;
                padding-left: 4px;
            }

            .gry {
                color: #a3a3a3;
                padding-left: 4px;

            }

            .red {
                color: red;
                padding-left: 4px;

            }

            .mt-right {
                margin-right: 10px;
            }
        }

        .mt-10 {
            margin-top: 15px;
            margin-bottom: 15px;
        }
    }

}

.doc-custom {
    .document-customheader {

        // padding: 15px;
        h3 {
            color: #000;
            font-size: 26px;

            svg {
                margin-right: 10px;
                color: #000;
            }

        }

    }

    .container-fluid {
        .overview-header {
            padding: 20px 0px 0px 28px;
        }
    }

    .line-after {
        span {
            color: #007ACC;
            font-size: 20px;
            position: relative;
            display: block;

            &:after {
                content: "";
                position: absolute;
                width: 92%;
                top: 50%;
                margin-left: 10px;
                border-top: 0.5px solid rgba(45, 45, 45, 0.15);
            }
        }
    }

    .tab-fin {
        .fin-table {
            tr {
                th {
                    font-weight: normal;
                }
            }

            .form-check-input {
                border: 1px solid #0000003b;
            }

            .form-check-input:checked {
                background-color: #007ACC;
            }
        }
    }

    .text-right {
        text-align: right;
    }

    .btn-cst-grey {

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 23px;
        border-radius: 10px;
        padding: 6px 10px;
        color: #2D2D2D;
        margin-right: 30px;
        border: unset;
        background: unset;

        svg {
            font-size: 20px;
            vertical-align: sub;
            margin-right: 0px;
        }

        &:hover {
            color: #007ACC;
            background: #ECECEC;
        }
    }

}

// #contentleft-data::-webkit-scrollbar,
// #contentright-data::-webkit-scrollbar {
//     width: 14px;

// }

// #contentleft-data::-webkit-scrollbar-thumb,
// #contentright-data::-webkit-scrollbar-thumb {
//     background-color: #c1c1c194;
//     border-radius: 0px;

// }

// #contentleft-data::-webkit-scrollbar-track,
// #contentright-data::-webkit-scrollbar-track {
//     background-color: #F1F1F1;
// }

// #contentleft-data::-webkit-scrollbar-button,
// #contentright-data::-webkit-scrollbar-button {
//     display: block;
//     background-color: #F1F1F1;
//     background-repeat: no-repeat;
//     background-size: 100%;
//     background-position: center;
// }

// #contentleft-data::-webkit-scrollbar-button:vertical:start:increment {
//     background-image: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="24" height="24" viewBox="0 0 24 24"%3E%3Cpath fill="%23c1c1c1" d="m11.3 14.3l-2.6-2.6q-.475-.475-.212-1.087T9.425 10h5.15q.675 0 .938.613T15.3 11.7l-2.6 2.6q-.15.15-.325.225T12 14.6q-.2 0-.375-.075T11.3 14.3Z"%2F%3E%3C%2Fsvg%3E');
// }

// #contentleft-data::-webkit-scrollbar-button:vertical:start:decrement {
//     display: none;
// }

// #contentleft-data::-webkit-scrollbar-button:vertical:end:increment {
//     display: none;
// }

// #contentleft-data::-webkit-scrollbar-button:vertical:end:decrement {
//     background-image: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="24" height="24" viewBox="0 0 24 24"%3E%3Cpath fill="%23c1c1c1" d="M9.425 14q-.675 0-.938-.613T8.7 12.3l2.6-2.6q.15-.15.325-.225T12 9.4q.2 0 .375.075t.325.225l2.6 2.6q.475.475.212 1.088t-.937.612h-5.15Z"%2F%3E%3C%2Fsvg%3E');
// }

// #contentright-data::-webkit-scrollbar-button:vertical:start:increment {
//     background-image: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="24" height="24" viewBox="0 0 24 24"%3E%3Cpath fill="%23c1c1c1" d="m11.3 14.3l-2.6-2.6q-.475-.475-.212-1.087T9.425 10h5.15q.675 0 .938.613T15.3 11.7l-2.6 2.6q-.15.15-.325.225T12 14.6q-.2 0-.375-.075T11.3 14.3Z"%2F%3E%3C%2Fsvg%3E');
// }

// #contentright-data::-webkit-scrollbar-button:vertical:start:decrement {
//     display: none;
// }

// #contentright-data::-webkit-scrollbar-button:vertical:end:increment {
//     display: none;
// }

// #contentright-data::-webkit-scrollbar-button:vertical:end:decrement {
//     background-image: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="24" height="24" viewBox="0 0 24 24"%3E%3Cpath fill="%23c1c1c1" d="M9.425 14q-.675 0-.938-.613T8.7 12.3l2.6-2.6q.15-.15.325-.225T12 9.4q.2 0 .375.075t.325.225l2.6 2.6q.475.475.212 1.088t-.937.612h-5.15Z"%2F%3E%3C%2Fsvg%3E');
// }

.custom-accordion-documents {
    .accordion {
        --bs-accordion-border-color: transparent;
    }

    .accordion-button {
        border-bottom: 0px solid #9e9e9e36;
    }

    #document-accordian {
        .accordion-header {
            display: flex;
            position: relative;

            .accordion-button {
                font-size: 13px;
                color: #a3a3a3;
                padding: 0px 10px;
                color: #a3a3a3;
                background-color: #ffffff;
                line-height: 0px;

                svg {
                    font-size: 16px;
                    color: #a3a3a3;
                    margin-right: 5px;
                }
            }

            .document-count {
                right: 14%;
                position: absolute;
                width: 20%;
                padding: 0.5rem;
                z-index: 99;
            }
        }
        .accordion-button:not(.collapsed) {
            color: #007ACC;
            background-color: #F8FBFF;
            padding-top: 10px;
            padding-bottom: 5px;

            svg {
                color: #007ACC;
            }
        }

        .doc-tb {
            background: #F8FBFF;
            color: #a3a3a3;

            tr:last-child {
                border-bottom: 0px solid transparent;
            }

            tr {
                td {
                    svg {
                        font-size: 16px;
                        color: #a3a3a3;
                        margin-right: 3px;
                    }
                }
            }
        }

        .action-btn {

            text-align: center;

            button {
                background: transparent;
                border: 0px;

                svg {
                    color: #A3A3A3;
                    font-size: 16px;

                    &:hover {
                        color: #007ACC;
                    }
                }
            }


        }
    }

    .doc-table {
        .list-task-tb {
            td {
                svg {
                    font-size: 16px;
                    color: #a3a3a3;
                    margin-right: 3px;
                }
            }
        }
    }

    .acd-bg {
        background-color: #F8FBFF;
    }
}

.wit-65 {
    width: 65%;
}

#document-id {
    .dropdown-menu-end[style] {
        left: 0px !important;
        right: 0 !important;
        border: 0.01rem solid #a3a3a3;
        box-shadow: unset;
    }
}

.tost-icon {
    color: #1E823A;
    margin-right: 10px;

    svg {
        font-size: 40px;
    }
}

.pd-left-0 {
    padding-left: 0px !important;
}

.pd-right-0
{
    padding-right: 0px!important;
}

.pd-lf-10 {
    margin-left: 15px !important;
    position: relative;

    &::after {
        content: '';
        width: 0px;
        height: 82%;
        position: absolute;
        border: 0.5px solid #007acc8f;
        top: 10px;
        left: 6px;
    }
}

.cst-toast {
    padding: 20px;
    padding-top: 10px;
    border-radius: 20px;
    background-color: #fff;
    --bs-toast-box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.05);
    box-shadow: var(--bs-toast-box-shadow);
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;

    .tost-content {
        p {
            margin-bottom: 5px;
        }

        span {
            font-weight: 300;
        }
    }
}

.notification-sidebar {
    margin-top: 50px;
    z-index: 9999;

    .offcanvas-header {
        .offcanvas-title {
            color: #2D2D2D;
            font-weight: 600;
            cursor: pointer;
        }

        .clear-all {
            color: #007ACC;
            border: 0px;
            background: 0px;
        }
    }

    .offcanvas-body {
        padding: 0px;

        .notification-data {
            border-bottom: 0.01rem solid #a3a3a347;
            padding: 15px;

            .tost-content {
                p {
                    color: #2D2D2D;
                    margin-bottom: 2px;

                    span {
                        font-weight: 600;
                    }
                }
            }
            .tost-icon {
                color: white;
                margin-right: 10px;
                width: 46px;
                height: 46px;
                border-radius: 50%;
                padding: 10px 11px;
                svg {
                    font-size: 24px;
                }
            }

            .cls-btn {
                display: none;
            }

            .mrk-btn {
                display: none;
            }

            .action-btn {
                // width: 20%;
            }

            &:hover {
                .cls-btn {
                    display: block;
                    background: #fff;
                    border: 0px;
                    align-self: center;
                    background: transparent;

                    svg {
                        vertical-align: text-top;
                        background: #fff;
                    }
                }

                .mrk-btn {
                    border: 0px;
                    background: #fff;
                    color: #007ACC;
                    font-size: 12px;
                    display: block;
                    float: right;
                    margin-right: -20px;
                    margin-top: -10px;
                }
            }
        }

        .notify-active {
            background: #E9F4FC;
            border-bottom: 1px solid #fff;
        }

        .nav-pills {
            padding: 0px 20px;
            border-bottom: 1px solid #a3a3a347;

            .nav-link.active,
            .nav-pills .show>.nav-link {
                padding-bottom: 0px;
            }

            .pad-2 {
                margin-right: 1.5rem;
            }

            .nav-link {
                padding-top: 0px !important;
                padding-bottom: 0px;
                padding-left: 0px;
                padding-right: 0px;
            }


        }
    }
}

.notification-data {
    .blue {
        background: #007ACC;
    }

    .orange {
        background: #FF8839;
    }

    .green {
        background: #1E823A;
    }

    .red {
        background: #FF033E;
    }

    .lightblue {
        background: #4397FF;
    }
}


.empty-notification-offcanvas {
    .offcanvas-body {
        position: relative;

    }

    .empty-notification {
        position: absolute;
        top: 30%;
        left: 16%;

    }
}

.rd-outer {
    margin-left: -4px;
    margin-top: 4px;
}

.red-dot {
    position: relative;
    float: left;

    a {
        position: absolute;
        top: -16px;
        left: -10px;

        svg {
            font-size: 22px;
            color: #d64d55;
        }

    }
}

.nav-link:focus,
.nav-link:hover {
    color: #007ACC;
    text-decoration: none;
}

#ct-btn-id {
    top: -6px;
    position: absolute;
    right: 31px;
    display: block;
}

.cust-toastdata {
    bottom: 10%;
    right: 10px;
}

#document-accordian {
    .action-btn {
        button {
            padding: 0;
        }
    }
}

.doc-height {
    height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.wid-90 {
    width: 90%;
}

.wid-20 {
    width: 20%;
}
.confirm-modal-content
{
    border-radius: 20px;
    .confirm-message
    {
        .header
        {
            padding: 15px;
            h3{
                
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 28px;
                text-align: center;
                color: #000000;
            }
            p{
                
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #a3a3a3                ;
                padding:10px;
    
            }
            .confirm-btn
            {
                background: #007ACC;
                padding: 5px 15px;
                font-size: 16px;
                line-height: 20px;
                color: #FFFFFF;
                border-radius: 10px;
                border: #007ACC;
                svg{
                    font-size: 20px;
                }
                &:hover{
                    background: #054C7B;
                }
            }
            .cancel-btn
            {
                padding: 5px 15px;
                font-size: 16px;
                line-height: 20px;
                color: #BC3C47;
                border-radius: 10px;
                border:1px solid #BC3C47;
                background: #fff;
                margin-left: 10px;
                svg{
                    font-size: 20px;
                }
            }
        }
    }
}
#dependency-task-modal
{
    .modal-content
    {
        padding: 15px;
        padding-bottom: 30px;

    }
    .modal-header{
        padding-left: 0px;
        padding-bottom: 6px;
        .btn-close
        {
            color:#000;
            opacity: 1;
        }
    }
    .modal-body
    {
        padding-left: 0px;
    }
    .dependency-title
    {
        color:#000;
        font-size: 18px;
    }
    
    .dep-tsk-header
    {   
        color:#000;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 15px;
        .dep-header
        {
            margin-right: 10px;
            svg
            {
                font-size: 24px;
                margin-right: 0px;
            }
        }
    }
    .dep-tsk-list
    {
        padding: 8px;
        border:1px solid #E7F3FF;
        border-radius: 5px;
        font-size: 13px;
        color:#2D2D2D;
        margin-bottom: 10px;
        .dep-status
        {
            margin-right: 20px;
            svg{
                font-size: 20px;
                margin-right: 2px;
            }
        }
        .dep-header
        {
            margin-right: 20px;
            svg{
                font-size: 18px;
                margin-right: 0px;
            }
        }
        &:hover{
            background: #E7F3FF;
        }
    }
   
}
#tsk-list
{
    .task-dropdown
    {
        .ped-icn
        {
            
                display: none;
         }
            
        &:hover{
            .ped-icn
            {
                
                    display: block;
            }
            
        }
    }
}
.i-href-task-blue
{
 
 font-style: normal;
 font-weight: 500;
 font-size: 13px;
 line-height: 23px;
 border-radius: 10px;
 padding: 6px 10px;
 color: #fff;
 margin-right: 10px;
 border:unset;
 background: #007ACC;
 svg{
     font-size: 16px;
     vertical-align: sub;
     margin-right: 0px; 
 }
 &:hover {
     color: #fff;
     background: #054C7B;
 }
 
}
.btn-close
{
    opacity: 1;
}
.btn-cr-grey
    {
        
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 23px;
        border-radius: 10px;
        padding: 6px 10px;
        color: #2D2D2D;
        margin-right: 10px;
        border:unset;
        background: unset;
        svg{
            font-size: 20px;
            vertical-align: middle;
            margin-right: 4px; 
        }
        &:hover {
            color: #007ACC;
            background: #ECECEC;
        }
        @media(max-width:1024px)
        {
            font-size: 12px;
            padding: 3px 5px;
            margin-right: 10px;
        }
    }
    .exclm-yel
    {
        color:#CF8A08;
        cursor: pointer;
        text-align: center;
        svg{
            font-size: 18px!important;
            @media(max-width:1024px)
            {
                font-size: 14px!important;
            }
            @media(max-width:600px)
            {
                font-size: 12px!important;
                margin-right: 0px!important;
            }
        }
        @media(max-width:600px)
        {
            text-align: left;
           
        }
    }
    .exclm-red
    {
        color:#E2435C;
        text-align: center;
        svg{
            font-size: 18px!important;
            @media(max-width:1024px)
            {
                font-size: 14px!important;
            }
            @media(max-width:600px)
            {
                font-size: 12px!important;
                margin-right: 0px!important;
            }
        }
        
        @media(max-width:600px)
        {
            text-align: left;
        }
    }
    .flex-data 
{
    display: flex;
    .avatar-xs 
    {
        width: 1.75rem;
        height: 1.75rem;
        font-size: 12px;
        .bg-soft {
            background-color: #4397FF !important;
            color: #fff !important;
            width: 1.75rem;
        }
     .bg-orange {
            background-color: #FF8839 !important;
            color: #fff !important;
            width: 1.75rem;
        }
       .bg-litgreen {
            background-color: #9CC5A0 !important;
            color: #fff !important;
            width: 1.75rem;
        }
    }
    span {
        padding-top: 4px;
        padding-left: 10px;
    }
}
    #dependency-task-modal
    {
        .modal-content
        {
            padding: 15px;
            padding-bottom: 30px;
    
        }
        .modal-header{
            padding-left: 0px;
            padding-bottom: 6px;
            .btn-close
            {
                color:#000;
                opacity: 1;
            }
        }
        .modal-body
        {
            padding-left: 0px;
        }
        .dependency-title
        {
            color:#000;
            font-size: 18px;
        }
        
        .dep-tsk-header
        {   
            color:#000;
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 15px;
            .dep-header
            {
                margin-right: 10px;
                svg
                {
                    font-size: 24px;
                    margin-right: 0px;
                }
            }
        }
        .dep-tsk-list
        {
            padding: 8px;
            border:1px solid #E7F3FF;
            border-radius: 5px;
            font-size: 13px;
            color:#2D2D2D;
            margin-bottom: 10px;
            .dep-status
            {
                margin-right: 20px;
                svg{
                    font-size: 20px;
                    margin-right: 2px;
                }
            }
            .dep-header
            {
                margin-right: 20px;
                svg{
                    font-size: 18px;
                    margin-right: 0px;
                }
            }
            &:hover{
                background: #E7F3FF;
            }
        }
       
    }
    #tsk-list
    {
        .task-dropdown
        {
            .ped-icn
            {
                
                    display: none;
             }
                
            &:hover{
                .ped-icn
                {
                    
                        display: block;
                }
                
            }
        }
    }
    .i-href-task-blue
    {
     
     font-style: normal;
     font-weight: 500;
     font-size: 13px;
     line-height: 23px;
     border-radius: 10px;
     padding: 6px 10px;
     color: #fff;
     margin-right: 10px;
     border:unset;
     background: #007ACC;
     svg{
         font-size: 16px;
         vertical-align: sub;
         margin-right: 0px; 
     }
     &:hover {
         color: #fff;
         background: #054C7B;
     }
     
    }
    .btn-close
    {
        opacity: 1;
    }
    .btn-cr-grey
        {
            
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 23px;
            border-radius: 10px;
            padding: 6px 10px;
            color: #2D2D2D;
            margin-right: 10px;
            border:unset;
            background: unset;
            svg{
                font-size: 20px;
                vertical-align: middle;
                margin-right: 4px; 
            }
            &:hover {
                color: #007ACC;
                background: #ECECEC;
            }
            @media(max-width:1024px)
            {
                font-size: 12px;
                padding: 3px 5px;
                margin-right: 10px;
            }
        }
    
    .task-modal
    {
        
        .modal-header
        {
            .dependency-title
            {
                font-size: 18px;
                color:#000;
                margin-bottom: 0px;
                margin-left: 10px;
            }
        }
    }
    .sticky-header
    {
        position: sticky;
        top: 0px;
        z-index: 999;
        background: #F5FAFF;
        color:#000;
        padding: 12px;
        .cr-hed
        {
            h6{
                font-size: 12px;
                color:#a3a3a3;
            }
            p{
                font-size: 13px;
            }
            .prj-btn{
                padding: 0px;
                font-size: 13px;
                svg{
                    font-size: 16px;
                    margin-right: 2px;
                }
            }
        }
    }
    .tsk-icons
    {
        float: right;
        font-size: 20px;
        padding-top: 20px;
        a{
            padding-right: 4px;
            cursor: pointer;
            color:#292929;
            svg
            {
                font-size: 18px;
            }
            &:hover{
            color:#007ACC;
            text-decoration: underline!important;
            }
        }
        .active{
            color: #007ACC;
            border-bottom: 1px solid #007ACC;
        }
    }
    .red{
            color: #d70015;
    }
    .create-task
    {
      
        .ad-task
        {
            .inputformContainer 
            {
                label{
                    font-size: 13px;
                }
            }
            .ad-textarea{
                height: 120px;
            }
            .inputformContainer {
                margin-bottom: 2.8rem;
            }
            .dep-left
            {
                padding-top: 10px;
                height: 485px;
                overflow-y: scroll;
            }
            .add-dependent
            {
                h5{
                    color:#000;
                }
                .notify-data
                {
                  background: transparent;
                  border: 0px;
                  svg
                  {
                    color:#a3a3a3;
                    font-size: 18px;
                  }
            
                }
                .dep-content
                {
                    padding: 15px;
                    
                    h3{
                        color:#000;
                        font-size: 18px;
                        margin-bottom: 3px;
                    }
                    p{
                        margin-bottom: 7px;
                        font-size: 13px;
                        color: #000;
                        letter-spacing: 1px;
                    }
                    h6{
                        color:#000;
                        font-size: 16px;
                        margin-top:5px;
                        margin-bottom: 5px;
                    }
                }
                .dep-info
                {
                    .dropdown-menu-lg
                    {
                        width:560px;
                        right: -100px !important;
                        border-radius: 14px;
                    }
                }
            }
            .dep-select
            {
                position: absolute;
                right: 0px;
                z-index: 999;
                top:5px;
                a{
                    svg{
                        font-size: 22px;
                        color:#000;
                        margin-top: 10px;
                        margin-right: 10px;
                        @media(max-width: 1024px)
                        {
                            font-size: 14px;
                        }
                    }
                }
            }
            .task-dep-list
            {
                padding: 12px;
                border: 1px solid #E7F3FF;
                position: relative;
                border-radius: 5px;
                margin-bottom: 10px;
                .tsk-id
                {
                    color: #a3a3a3;
                }
                .track-btn
                {
                    margin-left: 5px;
                }
    
                &:hover{
                    background: #E7F3FF;
                    .form-select {
                        background-color:#E7F3FF ;
                    }
                    .depdel-icon{
                        display: block;
                    }
                }
                .form-select {
                    background-position: right 0.5rem center;
                    background-size: 9px 10px;
                    width:85px;
                }
                .dep-name
                {
                    font-size: 14px;
                    margin-left: 10px;
                }
                .depdel-icon
                {
                    position: absolute;
                    right: 20px;
                    top:25px;
                    display: none;
                    button{
                        background:transparent;
                    border:0px;
                    color:grey;
                    svg{
                        font-size: 20px;
                    }
                    }
                }
            }
           
        }
        .dep-attachment
        {
            height: 485px;
            overflow-y: scroll;
            h3{
                font-size: 16px;
                margin-top:2px;
            }
            .at-btn
            {
                color:#a3a3a3;
                font-size: 16px;
                text-decoration: underline!important;
                margin-left: 10px;
                svg
                {
                    text-decoration: none;
                    margin-right: 5px;
                    font-size: 24px;
                }
            }
            .list-task-tb
            {
                td{
                    padding: 6px;
                    vertical-align: initial;
                }
            }
            .task-tbl
            {
                th{
                    border-top:1px solid #a3a3a338;
                }
            }
            .browse-file
            {
                width: 400px;
                margin: 0 auto;
                margin-top: 50px;
            }
        }
    }
    #create-task, #edit-task
    {
        @media (min-width: 1500px)
        {
            .modal-xl {
                --bs-modal-width: 1400px;
            }
        }
        @media (min-width: 576px)  and (max-width: 1200px) 
        {
            .modal-dialog {
                max-width: unset;
                margin-right: auto;
                margin-left: auto;
            }
        }
    
    }
    
    #project-list, #task-list, #tsk-list
{
    .prj-list
    {
        td {
            vertical-align: initial!important;
            color: #000!important;
            font-size: 12px;
            @media(max-width:1024px)
            {
                font-size: 10px;
            }
    }
}
}

.tasklist
{
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 50px;
    padding-bottom: 0px;  
    overflow-y: unset;
     .container-fluid
    {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
        padding-right: 0px;
        height: 100%;
        // overflow: hidden;
        .overview-header
        {
            padding: 20px 0px 0px 28px;
            position: sticky;
            top: 0;
            z-index: 999;
            background: #fff;
            border-bottom: 1px solid #a3a3a338;
        }
        .overview-content
        {
            padding: 0px 0px 0px 35px;
            height: 100%;
                // overflow: scroll;
            .row{
                height: 100%;
            }
        }
    }
}
.summary-tab
{
    td{
        padding: 0.6rem 0.3rem;

    }
}

.sm-task-list
    {
        .prj-nme
        {
            margin-left: 5px;
            position: relative;
            padding-left: 5px;
            font-size: 11px;
            color: #2D2D2D;
            &::after {
                content: "";
                position: absolute;
                left: 0;
                top: 2px;
                width: 1px;
                height: 14px;
                background: #2D2D2D;
            }
        }
        .track-btn
        {
            font-size: 12px;
        }
        h4{
            font-size: 14px;
            padding: 5px;
            margin-bottom: 0px;
            margin-top: 4px;
        }
    }
.ft-right
{
    float:right;
    .lab-dt
    {
        text-align: right;
        color:#a3a3a3;
    }
    .lab-dt-overdue
    {
        text-align: right;
        color:#D64D55;
        font-size: 12px;
    }
}
.lay-out
{
    height: 360px;
    // overflow-y: scroll;
    overflow: hidden;
    padding-bottom: 45px;
}