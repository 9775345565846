.cursor-pointer {
  cursor: pointer;
}

.logomain {
  p {
    color: #9E9E9E;
    font-size: 1.02rem;
  }

  .log-fnt {
    color: #2F5496;
    font-weight: 600;
    font-size: 2.8rem;

    .log-360 {
      color: #007ACC;
    }
  }

  .tm {
    color: #007ACC;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
  }
}

.login-pg {
  height: 100vh;
  overflow: auto;
}

.header-section {
  z-index: 9999 !important;
}

.frm-maincontent .form-main {
  z-index: 99 !important;
}

.log-txt {
  z-index: 999;
}

.container-facilityform {
  width: 1140px !important;
}

.overview-header {
  border-bottom: 1px solid #ccc;
}

.overview-header .ovr-data svg {
  color: #FFFFFF;
  font-size: 30px;
  margin: 15px 15px;
}

.ml-min20 {
  margin-left: -20px;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.cancel-btn {
  background: white;
  padding: 8px 26px;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #007ACC;
  border-radius: 10px;
  border-color: #007ACC;
  margin-left: 10px;
}

.date-picker-styles,
.react-datepicker__input-container {
  height: 45px !important;
  width: 100% !important;
  // border: 1px solid  #9E9E9E !important;
  border-radius: 5px;
}

.calendar-endDate {
  // bottom: 65% !important;
  // left: 92% !important;
  // position: relative;
  // cursor: pointer;
  // z-index:2
  color: #aaa;
  right: 10px;
  position: absolute;
  top: 13px;
  cursor: pointer;
  z-index: 2px;
  font-size: 20px;
}

.tabpagination {
  text-align: center;
  margin-top: 0px;
  position: relative;
  // padding-top: 3px;
  padding-bottom: 5px;

  @media (max-width: 575px) {
    margin: 25px 0px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 25px 0px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 25px 0px;
  }

  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0%;
    margin: 0%;

    li {
      list-style: none;
      height: 35px;
      width: 35px;
      border-radius: 50%;
      border: 1px solid #ced4da;
      cursor: pointer;
      margin: 0px 7px;
      line-height: 30px;
      text-align: center;
      transition: 0.2s all linear;

      &:hover {
        background-color: #eff2f7;
        color: #74788d !important;
        transition: 0.2s all linear;

        svg {
          color: #74788d !important;
        }
      }

      a {
        text-decoration: none;
        color: inherit;
        font-size: 12px;
        display: inline-block;
        width: 100%;
      }
    }

    .ic-arrow {
      line-height: 30px;

      a {
        .iconify {
          color: #74788d;
        }
      }
    }

    .numactive {
      background-color: #007ACC;
      color: white;
    }
  }

  p {
    position: absolute;
    right: 30px;
    top: 5px;
    font-size: 12px;
    margin: 0%;

    @media (max-width: 991px) {
      position: unset;
      margin-top: 15px;
    }
  }

  .tooltipcls {
    transform: none !important;

  }

}

.react-datepicker-popper {
  z-index: 2 !important;
}

.crt-project-title {
  font-size: 24px;
  color: #2D2D2D;
}

.label.desred:after {
  content: " *";
  color: #D70015;
  font-weight: 600;
}

.count {
  position: absolute;
  right: 0;
  top: 99px;
}

textarea {
  resize: none;
}

.pagination-container {
  position: relative;
  top: 70px;
}

.prj-overview-right .card-view {
  padding: 3px 10px !important;
}

.pr-over {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 50px !important;
  padding-bottom: 0px !important;
}

.btn-close {
  width: 3rem;
}

.disabledArrows {
  opacity: 0.6;
  pointer-events: none;
}

.circel-progress-bar {
  height: 65px;
  width: 65px;
  margin-left: 20px;
  margin-top: 3px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @media(max-width:1024px) {
    margin-left: 0px;
  }
}

.custom-progress-value {
  position: absolute;
  font-size: 14px;
  font-weight: 600;
  color: #9E9E9E;
}

.wit-18 {
  width: 18%;
}

// .prj-btn {
//   padding: 6px 12px;
//   border-radius: 6px;
//   font-size: 12px;
// }

.prj-progressbar {
  .progress {
    width: 90%;
  }
}

.prj-over {
  padding: 6px 12px;
  width: fit-content;
  border-radius: 6px;
  font-size: 12px;

  @media(max-width:1024px) {
    width: 85% !important;
  }

  svg {
    margin-right: 5px;
    font-size: 20px;
  }
}

.red-btn {
  // border: 2px solid #E2435C;

  svg {
    color: #E2435C;
  }
}

.disabled-textbox {
  cursor: default;
  background-color: #eff2f7;
  pointer-events: none !important;
}

.ontrack {
  color: #48B473 !important;
}

.at-risk {
  color: #E2435C !important;
}

.requires-attention {
  color: #CF8A08 !important;
}

.red-btn {
  // border: 2px solid #E2435C;

  svg {
    color: #E2435C;
  }
}

.bg-red {
  background: #E2435C;
}

.red {
  color: #E2435C;
}

.pgr {
  color: #9E9E9E;
  font-size: 14px;
}

.tooltip {
  z-index: 99 !important;
}

.dateicon {
  z-index: 2 !important;
}

.col-85 {
  width: 85%;
}

.col-15 {
  width: 15%;
}

.track-btn {
  .prg-bar {
    .box {
      width: 40%;
    }

    .box-time {
      width: 66% !important;
      padding-left: 30px !important;
    }
  }
}

.box-content {
  .box-time {
    .duedate {
      font-size: 11px !important;
    }
  }

}

.p_viewall {
  font-size: 14px;
  margin-bottom: 0px;
  line-height: 36px;
  padding: 10px;
}
#colright-data, #contentright-data .dropdown-toggle::after {
  display: none;
}
.custom-tag::after{
  display: none;

}
.react-pdf__Page__textContent span {
  direction: rtl;
}
.react-pdf__Page__canvas{
  display: none;
}
.doc-view-button{
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 35px;
    height: 35px;
    padding: 0px;
    margin: 0px 0px 0px 5px;
    text-align: center;
    font-size: 18px;
    border: 0px;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    border-radius: 35px;
    opacity: 1;
    pointer-events: all;
    box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 3px;
}
.file-name{
  flex: 1 1 0%;
  text-align: left;
  color: rgb(135 122 122);
  font-weight: bold;
  margin: 0px 10px;
  overflow: hidden;
}
.doc-nav{
min-width: 150px;
  // display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: end;
  justify-content: flex-end;
  margin: 0px 10px;
  color: rgb(0, 0, 0);
  // margin-right: 100px;
  p{
    margin-top: 10px;
    margin-bottom: 0px;
    margin-right: 10px;
  }
}
.pdf-control{display: flex;
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 1;
  -webkit-box-pack: end;
  justify-content: flex-end;
  padding: 8px;
  margin-top: 10px;
  p{
    margin-top: 6px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
  .pdf-container {
    height: 524px; /* Adjust the height as per your requirements */
    overflow: auto;
    width: "100%";
  }
  #doc-scroll::-webkit-scrollbar {
    width: 14px;
  }
  #doc-scroll::-webkit-scrollbar-thumb {
    background-color: #c1c1c194;
    border-radius: 0px;
  }

.pdf-controls
{ 
    margin-left: 40px;
    @media(max-width:1024px)
    {
        margin-left: unset;
    }
    @media(max-width:768px)
    {
        margin-left: unset;
        font-size: 8px;
    }
   
   a{
    svg{
        font-size: 25px;
      //  color:#fff;
       @media(max-width:768px)
    {
        font-size: 8px;
    }
    }
   } 
}
.pdf-download, .pdf-print
{
    a{
        // color:#fff;
        margin-left: 80px;
        @media(max-width:1024px)
        {
            margin-left: 10px;
        }
        @media(max-width:768px)
        {
            margin-left: 10px;
            font-size: 8px;
        }
        svg
        {
            margin-right: 5px;
            font-size: 16px;
        }
    }
}
.zoomable-image {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.zoomable-image img {
  transition: all 0.3s ease;
}

.zoomable-image img.zoomed {
  transform: scale(2); /* Adjust the scale value to zoom in further */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  cursor: zoom-out;
}
.doc-viewer-modal
{
  
  
  .btn-close {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    width: unset;
    height: unset;
    padding: 0.25em 1.25em;
    // color: #fff;
    background: transparent ;
    border: 0;
    border-radius: 0.25rem;
    opacity: 1; 
    svg
    {
        margin-right: 5px;
    }
}
  
  .r-hd-sticky
  {
     position: sticky;
      top: 0;
      background: #fff;
      z-index: 999;
      padding-top: 10px;
  }
  .md-bd-h
  {
    height:600px;
    overflow-y: scroll;
    padding-top: 0px;
    overflow-x: hidden;
  }
  .pdf-iframe{
    height:600px;
    overflow-y: scroll;
    padding-top: 0px;
    overflow-x: hidden;
    width: 1090px;
  }
}
 .dropdown-toggle::after{
  display: none;
}
.dep-pr-title{
  cursor: pointer;
  font-size: 13px;
}
.dep-task-null{
  cursor: auto;
}
.start-loader{
  min-height: 210px;
}
.modal-loader{
  margin-left: 200px;

}
#printTooltip{
  z-index: 1000 !important
}  
#printTool{
  z-index: 2000 !important
}  
.add-contact-cr {
	font-size: 14px !important;
	color: #a3a3a3 !important;
}
.clr-red{
	color: red !important;
}
.clr-grn{
	color: green !important;
}
.clr-blue{
	color: #007ACC !important;
	position: relative;
	bottom: 2px;
	left: 5px;
}
.alc-dt{
	padding-left: 10px;
	label{
		margin-bottom: 10px;
		color:#A3A3A3;
	}
	P{
		color: #2D2D2D;
		font-size: 14px;
	}
}
.Exclude-docs{
	margin: 20px 40px;
	page-break-inside: always;
}
.Exclude-docs h4{
	margin-bottom: 20px !important;
	margin-top: 20px !important;
}
.Exclude-docs h6{
	margin-top: 20px !important;
}
.Exclude-docs table{
  margin: 10px 0px !important;
}
.print-icon{
	cursor: pointer;
	float: right;
	margin: 0 40px;
	z-index: 9999;
}
.Exclude-docs thead tr{
  margin-top: 100rem !important;
}
@media print {
  @page {
    size: A4;
    margin: 20mm 0mm 20mm 0mm !important;
  }
  .watermark {
    position: fixed;
    top: 50vh;
    z-index: 9;
    width: 50vw;
    page-break-after: always;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: .1;
  }
  div.page-header {
    position: relative;
    display:block !important;
    text-align: center !important;
    z-index: 9;
    top: 100px !important;
    left: 0;
    right: 0;
    width: 100%;
    height: 100px !important;
    font-size: 17px;
    color: #000000;  
    page-break-after: always;  
  }
  span.info  {  
    font-size: 12px !important; 
  } 
}
.menu {
  display: none; /* Hide the menu by default */
}
 .pf-hed-cus
 {
  margin-left: auto;
  margin-right: 0px;
  justify-content: end;

 }
 .f-btn{
  position: absolute;
  top: 10px;
  z-index: 999;
  right: 158px;
 }
 .wt-14-2
 {
  width:14.28%;
 }
 .cr-del
 {
  margin-top: 8px;
  margin-left: -10px;
 }
 .wt-70
{
	width: 70%;
}
.wt-15
{
	width: 15%;
}
.js-cn-end
{
	justify-content: end;
}
